import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { STRING_TYPE_OBJ } from 'src/app/core/dummy/app';
import { DataService } from 'src/app/core/services/data.service';
import { ConvertService } from '../../services/convert.service';

@Component({
  selector: 'app-add-mathtype-input',
  templateUrl: './add-mathtype-input.component.html',
  styleUrls: ['./add-mathtype-input.component.scss']
})
export class AddMathtypeInputComponent implements OnInit {
  @ViewChild('focusInput') inputEl: ElementRef;

  @Input() item: any;
  @Output() onSubmit = new EventEmitter()
  form: FormGroup;
  constructor(private fb: FormBuilder,
    private ds: DataService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      text: [this.item ? this.item.text || null : null, Validators.required]
    })
  }

  _onSubmit(f: any) {
    const { text } = f;
    const key = this.item ? this.item.key : this.ds.createId();
    const item = {
      key: key,
      pageKey: ConvertService.pageKey(),
      text,
      type: STRING_TYPE_OBJ.MATH_TYPE
    }
    this.onSubmit.emit(item)
    this.form.reset();
    this.inputEl.nativeElement.focus()
  }

}
