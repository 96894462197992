<div class="print-wrapper no-shadow p-0">
  <div class="row-header">
    <div class="title">
      Print Preview
    </div>
    <div class="btn-print">
      <button mat-icon-button (click)="print()" class="print-btn"><i class="material-icons">&#xE8AD;</i></button>

    </div>
    <div class="close-btn-report ml-5" (click)="dialogRef.close('yes')">
      <i class="material-icons">&#xE14C;</i>
    </div>
  </div>

  <div class="row-body">
    <div class="card-wrapper pt-0">
      <div class="report-wrapper">
        <div id="printD" class="print-wrapper atTop">
          <section class="sheet relative">
            <div class="sheet-content">
              <div class="report-header">
                <div class="d-flex m-header">
                  <div class="flex-1 text-center">
                    <div class="logo-block">
                      <img src="../../../assets/images/logo.png" alt="">
                    </div>
                    <div class="com-info text-center">
                      <div class="t1">
                        {{"name" | getSchoolConfig}}
                      </div>
                      <!-- <div class="tl">
                          Sila Samādhi Paññā
                        </div> -->
                      <div class="tl">
                      </div>
                    </div>
                  </div>
                  <div class="flex-1">

                  </div>
                </div>
                <div class="">
                  <div class="recept-title" (click)="check()">Daily Shift Summary</div>
                  <div class="recept-date">On {{data?.create_date?.toDate()|date:'medium'}}</div>
                </div>
                <div class="user-info-report">
                  <h4>Cashier: {{data?.create_by?.displayName}} - {{data?.create_by?.email}}</h4>
                </div>
              </div>
              <div class="report-body mt-5">
                <table class="listing-table daily-table-rp  no-border-bottom">
                  <thead>
                    <tr>
                      <th class="text-center">No</th>
                      <th class="">Program</th>
                      <th class="">Term</th>
                      <th class="text-right"> Amount</th>
                      <th class="text-center"> Signature</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of store?.groupTerm; let i=index">
                      <th class="text-center">{{i+1}}</th>
                      <td class="text-left">
                        <span>{{item?.invoice_type?.text}}</span>
                      </td>
                      <td class="text-left">{{item?.issue_year?.name}}</td>
                      <td class="text-right">
                        {{(item?.amount|sumTuitionFee:store?.data:item?.payment_year?.key:item?.invoice_type?.key:"amount")|currency}}
                      </td>
                      <td class="no-bt"></td>
                    </tr>
                    <tr *ngFor="let item of store?.groupProgram; let i=index">
                      <th class="text-center">{{i+1+store?.groupTerm?.length}}</th>
                      <td class="text-left">
                        <span>{{item?.invoice_type?.text}}</span>
                      </td>
                      <td class="text-left">{{item?.issue_year?.name}}</td>
                      <td class="text-right">
                        {{(item?.amount|sumReport:store?.data:item?.invoice_type?.key:"amount")|currency}}
                      </td>
                      <td class="no-bt"></td>
                    </tr>
                    <tr *ngFor="let item of store?.groupOtherFee; let i=index">
                      <th class="text-center">{{store?.groupTerm?.length+store?.groupProgram?.length+1+i}}</th>
                      <td class="text-left">
                        <span>{{item?.course?.otherFee?.name}}</span>
                      </td>
                      <td class="text-left">{{item?.issue_year?.name}}</td>
                      <td class="text-right">
                        {{(item?.amount|sumOtherFee:store?.data:item?.course?.otherFee?.key:"amount")|currency}}
                      </td>
                      <td class="no-bt"></td>
                    </tr>
                    <tr *ngIf="store?.pettyCash.length>0">
                      <th class="text-center">
                        {{store?.groupOtherFee?.length+store?.groupTerm?.length+store?.groupProgram?.length+1}}</th>
                      <td class="text-left">
                        <span>Expense</span>
                      </td>
                      <td class="text-center"></td>
                      <td class="text-right">
                        -{{(store?.pettyCash|sumArray:"price")|currency}}
                      </td>
                      <td class="no-bt"></td>
                    </tr>
                    <tr *ngIf="store?.installmentData.length>0">
                      <th class="text-center">
                        {{store?.groupOtherFee?.length+store?.groupTerm?.length+store?.groupProgram?.length+2}}</th>
                      <td class="text-left">
                        <span>Deposit</span>
                      </td>
                      <td class="text-center"></td>
                      <td class="text-right">
                        {{(store?.installmentData|sumArray:"price")|currency}}
                      </td>
                      <td class="no-bt"></td>
                    </tr>
                    <tr class="t-tr">
                      <td colspan="4" class="text-center">
                        <strong>Total</strong>
                      </td>
                      <td class="text-right bt bg">
                        <strong>
                          {{store?.totalAmount|currency}}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="report-body mt-5">
                <table class="listing-table daily-table-rp  no-border-bottom">
                  <thead>
                    <tr>
                      <th class="text-center">Type</th>
                      <th class="text-center"></th>
                      <th class="text-center">Equal R</th>
                      <th class="text-center"> Equal $</th>
                      <th class="text-center"> Other</th>

                    </tr>
                  </thead>
                  <tbody *ngIf="store?.moneyDollars?.length>0&&store?.moneyRiels?.length>0">
                    <!-- riels money-->
                    <tr *ngFor="let item of store?.moneyRiels; let i=index">
                      <td class="text-center">
                        <span>{{item?.operant | number}}</span>
                      </td>
                      <td class="text-center">
                        {{item?.total/item?.operant}}
                        <!-- <input class="input-center" type="number" [formControlName]="item.key"> -->
                      </td>
                      <td class="text-center min120">
                        {{item?.total|currency:'៛'}}
                      </td>
                      <td class="no-bt"></td>
                      <td class="no-bt"></td>
                    </tr>

                    <!--  -->
                    <tr class="t-tr">
                      <td class="text-left">
                        <strong>Total in riels</strong>
                      </td>
                      <td></td>
                      <td class="text-center">
                        <strong>
                          {{(store?.moneyRiels|sumArray:"total"|currency:'៛')}}
                        </strong>
                      </td>
                      <td class="text-right bt">
                        <strong>
                          {{((store?.moneyRiels|sumArray:"total")/4000)|currency:'$'}}
                        </strong>
                      </td>
                      <td class="no-bt"></td>
                    </tr>

                    <!-- riels money-->
                    <tr *ngFor="let item of store?.moneyDollars; let i=index">
                      <td class="text-center">
                        <span *ngIf="item?.key!=='checkQty'">{{item?.operant}}</span>
                        <span *ngIf="item?.key==='checkQty'">Check</span>
                      </td>
                      <td class="text-center">
                        {{item?.total/item?.operant}}
                        <!-- <input class="input-center" type="number" [formControlName]="item.key"> -->
                      </td>
                      <td class="no-bt"></td>
                      <td class="text-center min120">
                        {{item?.total|currency:'$'}}
                      </td>
                      <td class="no-bt"></td>
                    </tr>

                    <!--  -->
                    <tr class="t-tr">
                      <td class="text-left">
                        <strong>Total in USD</strong>
                      </td>

                      <td class="text-center" colspan="2">
                        <strong>
                          Total
                        </strong>
                      </td>
                      <td class="text-right bt">
                        <strong>
                          {{(store?.moneyDollars|sumArray:"total")|currency:'$'}}
                        </strong>
                      </td>
                      <td class="no-bt"></td>
                    </tr>
                    <!--  -->

                    <tr class="t-tr">
                      <td class="text-center" colspan="4">
                        <strong>Grand Total</strong>
                      </td>
                      <td class="text-right bt bg">
                        <strong>
                          {{((store?.moneyRiels|sumArray:"total")/4000) +
                          (store?.moneyDollars|sumArray:"total")|currency:'$'}}
                        </strong>
                      </td>
                    </tr>

                  </tbody>

                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>