import { Component, OnInit } from "@angular/core";
import { Environment } from 'src/app/core/stores/environment';
import { LocalStorage } from "@ngx-pwa/local-storage";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  expansions = {
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
    nine: false,
    ten: false,
    eleven: false,
    twelve: false,
  };
  toggleSidebar: boolean = true;
  disabled: boolean = true;
  profile: any;
  isAdmin = false;
  programList = [];

  constructor(
    public env: Environment,
    protected localStorages: LocalStorage,

  ) { }

  ngOnInit() {
    this.isAdmin = false;
    // this.env.getUserCallback(res => {
    //   this.isAdmin = getAdminUser(res);
    // })
    this.env.getUser(user => {
      if (user && user.campus) {
        const { key } = user.campus;
        this.env.fetchCampusProgram(key, docs => {
          this.programList = docs
        });
      }
    })

    const toggleSidebar = localStorage.getItem("toggleSidebar");
    this.toggleSidebar = JSON.parse(toggleSidebar);
    if (!this.toggleSidebar) {
      let body = document.getElementsByClassName("page-wrapper")[0];
      body.classList.toggle("toggled-sidebar");
    }
    // this.breakpointObserver
    //   .observe(['(max-width: 1024px)'])
    //   .subscribe((state: BreakpointState) => {
    //     if (state.matches) {
    //       let body = document.getElementsByClassName("page-wrapper")[0];
    //       body.classList.toggle("toggled-sidebar");
    //     }
    //   });
    const expansions = JSON.parse(localStorage.getItem("expansions"));
    if (expansions) {
      this.expansions = expansions
    }
  }

  ngOnDestroy() {
    const { fetchCampusProgramRef } = this.env;
    fetchCampusProgramRef && fetchCampusProgramRef.unsubscribe();
  }

  togglesidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-sidebar");
    this.toggleSidebar = !this.toggleSidebar;
    localStorage.setItem('toggleSidebar', JSON.stringify(this.toggleSidebar));

  }

  onOpened(i) {
    const isOpen = true;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));

  }
  onClosed(i) {
    const isOpen = false;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));

  }
}
