import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(data: any[], valueKey: any, subject: boolean): any {
    if (data) {
      if (subject) return data.filter(m => m.grade.key === valueKey)
      return data.filter(m => m.subjectKey === valueKey)
    }
    return null;
  }

}
