import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daySelected'
})
export class DaySelectedPipe implements PipeTransform {
  transform(days: any, field?: any): any {
    if(days){
      return days[field.toLowerCase()];
    }
    return null;
  }
}


