import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { FooterComponent } from './layouts/footer/footer.component'
import { HeaderComponent } from './layouts/header/header.component';
import { SharedModule } from './shared/shared.module'
import { CoreModule } from './core/core.module';
import { DashboardNavComponent } from './layouts/dashboard-nav/dashboard-nav.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginShellComponent } from './modules/auth/pages/login-shell/login-shell.component';
import { CampusPanelComponent } from './layouts/campus-panel/campus-panel.component';
import { ShiftReportComponent } from './layouts/shift-report/shift-report.component';
import { StudentPanelComponent } from './layouts/student-panel/student-panel.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { HttpModule } from '@angular/http';
import { CashierPanelComponent } from './layouts/cashier-panel/cashier-panel.component';
import { OverviewLayoutComponent } from './layouts/overview-layout/overview-layout.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { MainSidebarComponent } from './layouts/main-layout/main-sidebar/main-sidebar.component';
import { MainHeaderComponent } from './layouts/main-layout/main-header/main-header.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { QuillModule } from 'ngx-quill';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    DashboardLayoutComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    DashboardNavComponent,
    LoginShellComponent,
    CampusPanelComponent,
    ShiftReportComponent,
    StudentPanelComponent,
    SidebarComponent,
    CashierPanelComponent,
    OverviewLayoutComponent,
    MainLayoutComponent,
    MainSidebarComponent,
    MainHeaderComponent,
    AppLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    LottieModule.forRoot({ player: playerFactory }),
    AngularFirestoreModule,
    CoreModule,
    SharedModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    HttpModule,
    TimepickerModule.forRoot(),
    QuillModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
