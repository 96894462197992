import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSessionShow'
})
export class FilterSessionShowPipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    return values.filter(m => m.session_show && m.session_show.key === args)
  }

}
