import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import { status, paymentStatus, recordStatus, SUBJECT_DATA } from "../dummy/stauts";
import { DataService } from '../services/data.service';
import { AdministratorService } from '../services/administrator.service';
import { attendancePolicyObj, MappingService, pageKey, pushToArray, pushToObject, attendantRemarkObj, studentObj, toDateKey, toMonthKey, userObj } from 'src/app/shared/services/mapping.service';
import { ConvertService, toHourMM, toTimeHourMMA } from 'src/app/shared/services/convert.service';
import { IStudentAccount } from '../interfaces/student';
import * as firebase from 'firebase/app';
import { Observable, of, Subscription } from "rxjs";
import { distinct } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Pages } from "../dummy/pages";
import * as moment from "moment";
import { ATTENDANCE_MOVEMENT_SOURCE, CREATE_TIME_TYPE, HR_USER_TYPES } from "../dummy/config";
import { AuthStore } from "./auth.store";
import { ATTENDANCE_TYPE } from "../dummy/app";

@Injectable({ providedIn: 'root' })
export class StudentStore {
  @observable public scholarship = null;
  @observable public empty = false;
  @observable public admissions = null
  @observable public admissionKey = null
  @observable public data = null;
  @observable public loading = false;
  @observable public academic = null;
  @observable public process = false;
  @observable public term = null;
  @observable public student = null;
  @observable public studentTerm = null;
  @observable public creditAttempt = null;
  @observable public creditEarned = null;
  @observable public totalPoint = 0;
  @observable public totalCredit = 0;
  @observable public totalGPA = 0;
  @observable public selectedAdmission = null;
  @observable public selectedAcademicYear = null;
  @observable public admissionDate = null;
  @observable public filterType = null;
  @observable public invoiceData = [];
  @observable public invoiceUnpaid = [];
  @observable public scholarshipdata = null;
  @observable public scholarship_penalty = null;
  @observable public creditNote = null;
  @observable public course = null;
  @observable public schedules = null;
  @observable public schedulesBatch = null;
  @observable public emptyCourse = false;
  @observable public invoices = [];

  @observable public prepaid = null;
  @observable public installment = null;
  @observable public payPageKey = null;
  @observable public studentPrepaid = [];
  @observable public studentTesting = null;
  @observable public studentAdmission = null;

  @observable studentAdmissions: any[] = [];

  @observable
  public transfer = null;

  constructor
    (
      private db: AdministratorService,
      private ds: DataService,
      private afs: AngularFirestore,
      private auth: AuthStore
    ) { }

  @observable loadingInvoice: boolean = true;
  @observable fetchTestingInvoiceRef: any = null;
  @action
  fetchTestingInvoice(studentKey) {
    this.loadingInvoice = true;
    this.fetchTestingInvoiceRef = this.ds.studentInvoiceRef(studentKey)
      .valueChanges()
      .subscribe(docs => {
        this.invoices = docs;
        this.empty = docs.length == 0;
        this.loadingInvoice = false;
      });
  }

  @observable fetchNationalityToArrayRef: any = null;
  @action
  fetchNationalityToArray(callback) {
    this.loading = true;
    this.fetchNationalityToArrayRef = this.ds
      .nationalityArrayRef()
      .valueChanges()
      .subscribe(docs => {
        this.loading = false;
        callback(docs);
      });
  }

  @observable fetchStudentAdmissionRef: any = null;
  @action
  fetchStudentAdmission(studentKey: string) {
    this.loading = true;
    this.fetchStudentAdmissionRef = this.ds.studentAdmissionByStudentRef(studentKey)
      .valueChanges()
      .subscribe(docs => {
        this.studentAdmissions = MappingService.orderByDesc(docs, "academicYear.startDate");
        this.loading = false;
      })
  }

  @action
  fetchStudentByAdmission(key, callback) {
    this.loading = true;
    this.ds.studentAdmissionDBRef(key).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.studentAdmission = docs[0];
      } else this.studentAdmission = null;
      this.loading = false;

      callback(this.studentAdmission)
    })
  }

  @action
  async fetchOtherServiceFee() {
    const docs = await this.ds.otherServiceFeeRef().get().toPromise();
    return MappingService.orderBy(pushToArray(docs), "order");
  }

  @action
  async resetStudentTesting(testing: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const { programLevel, subject_type, key } = testing
    const { program } = programLevel
    const { test_KGE, test_IEP } = sysDoc

    let subjectType = subject_type
    if (program.key === test_KGE.key || program.key == test_IEP.key) {
      subjectType = SUBJECT_DATA.khmer;
    }

    const testingRef = this.ds.testingFireRef().doc(key);
    const testingQuestions = MappingService.pushToArray(await this.ds.testingDBRef().doc(key).collection("placementTest").get().toPromise())
    if (testingQuestions && testingQuestions.length > 0) {
      testingQuestions.map((m) => {
        batch.delete(testingRef.collection("placementTest").doc(m.key))
      })
    }

    batch.update(testingRef, {
      used: false,
      subject_type: subjectType
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  async setStudentID(studentId, student: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const studentRef = this.ds.studentFireRef();
    const studentAccountRef = this.ds.studentAccountFireRef();
    const settingRef = this.ds.settingFireStoreFire();

    const { puc_id } = student
    //===== New ID
    if (!puc_id) {
      const pucID = studentId ? `${studentId}` : ConvertService.generate_puc_id(sysDoc);
      const emailStudent = pucID + "@gmail.com";
      student.puc_id = pucID;
      student.email = emailStudent;
      const studentAccount: IStudentAccount = {
        key: student.key,
        create_date: new Date(),
        create_by: MappingService.userObj(user),
        status: recordStatus.active,
        puc_id: pucID,
        email: emailStudent,
        fileUrl: null,
        displayName: student.full_name,
        studentKey: student.key,
        phone: student.mobile_phone,
        token: null,
        pinCode: null,
        student: MappingService.studentObj(student),
      };

      batch.update(studentRef.doc(student.key), {
        puc_id: pucID,
        email: emailStudent,
      })
      batch.set(studentAccountRef.doc(studentAccount.key), studentAccount);

      // NEW ID NUMBER
      if (!studentId) {
        batch.update(settingRef, { puc_id: firebase.firestore.FieldValue.increment(1) });
      }
    }

    batch.commit().then(async () => {

      const studentAccount = await this.ds.studentDocument(student.key).get().toPromise();
      const studentData = MappingService.pushToObject(studentAccount);
      this.process = false;
      callback(true, studentData)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  fetchStudentSchedule(termKey, studentKey) {
    this.loading = true;
    this.course = [];
    this.ds.studentCurrentScheduleRef(termKey, studentKey).valueChanges().subscribe(docs => {
      this.course = docs;
      this.emptyCourse = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchEnglishStudentSchedule(studentKey: string, term: any, admission: any) {
    this.loading = true;
    const { institute, key } = term;
    this.schedules = [];
    this.ds.studentDoc(studentKey).valueChanges().subscribe(doc => {
      const studentData = doc;
      const batch = studentData[institute.key];
      if (batch) {
        this.ds.batchLevelRef().doc(batch.key).collection("schedules", ref => ref
          .where("term.key", "==", key)).valueChanges().subscribe(docs => {
            if (docs && docs.length > 0) {
              this.schedulesBatch = docs[0];

              const termAdmission = admission.term;
              const termSchedule = this.schedulesBatch.term;
              if (termAdmission.startDateKey <= termSchedule.startDateKey) {
                this.schedules = docs;
              }
            }
            this.loading = false;
          })
      } else {
        this.schedules = [];
        this.loading = false;
      }
    })
  }

  @action
  fetchScheduleDocument(termKey, scheduleKey) {
    return this.db.academicTermDocRef().doc(termKey).collection("schedules").doc(scheduleKey);
  }

  @action
  fetchInvoiceData(key) {
    this.process = true;
    this.invoiceData = [];
    this.invoiceUnpaid = [];
    this.ds.studentInvoiceRef(key).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.invoiceData = docs.filter(m => m.isPaid.key === paymentStatus.paid.key);
        this.invoiceUnpaid = docs.filter(m => !m.isVoid && m.isPaid.key === paymentStatus.unpaid.key);
      }
      this.process = false;
    });
  }

  @action
  fetchScholarshipPenalty(studentkey) {
    this.loading = true;
    this.ds.scholarship_penaltyRef(studentkey).valueChanges().subscribe(docs => {
      this.scholarship_penalty = docs;
      this.loading = false;
    })
  }

  @action
  fetchScholashipData(key) {
    this.process = true;
    this.ds.allscholarshipRef(key).valueChanges().subscribe(docs => {
      this.scholarshipdata = docs;
      this.process = false;
    });
  }
  @action
  hiddenAndShowCourse(studentKey, admissionKey, courseKey, courseStatus, user, callback) {
    this.process = true;
    this.db.transcriptCourseRef(studentKey, admissionKey, courseKey).update({
      status: courseStatus,
      admissionKey: admissionKey,
      studentKey: studentKey,
      update_by: MappingService.userObj(user),
      update_date: new Date(),
    }).then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  deleteAdmission(key, callback) {
    this.process = true;
    this.db.admissionRef().doc(key).update({
      status: status[2]
    }).then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  fetchAdmission(studentKey, callback) {
    this.loading = true;
    this.db.fetchAdmission(studentKey).valueChanges().subscribe(doc => {
      const list = doc.filter(m => m.major);
      const docs = MappingService.orderByDesc(list, 'program.order');
      this.admissions = docs;
      this.selectedAdmission = docs.length > 0 ? docs[0] : null;
      this.loading = false;
      callback(docs);
    })
  }

  @action
  fetchUnpaidInvoice(key) {
    this.process = true;
    this.ds.studentInvoiceRef(key).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.invoices = docs.filter(m => !m.isVoid && m.isPaid.key === paymentStatus.unpaid.key);
      }
      this.process = false;
    });
  }

  @action
  fetchSelectedAdmission(studentKey, admissionKey, callback) {
    this.loading = true;
    this.db.fetchAdmission(studentKey).valueChanges().subscribe(docs => {
      this.admissions = docs;
      let aKey = admissionKey;
      if (!admissionKey) aKey = docs[0].key;
      const admission = docs.filter(m => m.key === aKey)[0];
      this.selectedAdmission = docs[0];
      if (admission.length > 0) {
        this.selectedAdmission = admission[0]
      }
      this.loading = false;
      callback(docs);
    })
  }

  @action
  fetchStudentTransfer(studentKey, callback) {
    this.process = true;
    this.ds.studentTransfer(studentKey).valueChanges().subscribe(docs => {
      this.process = false;
      const data = MappingService.orderByDesc(docs, "page_key");
      callback(data);
    })
  }

  @action
  fetchAdmissionByKey(admissionKey, callback) {
    this.loading = true;
    this.db.admissionRef().doc(admissionKey).valueChanges().subscribe(doc => {
      this.admissions = doc;
      this.loading = false;
      callback(doc);
    })
  }

  @action
  resoleProgram(studentCode, studentKey) {
    this.db.transcriptStudentRef(studentCode).valueChanges().subscribe(snapshots => {
      const subjects = [];
      snapshots.forEach(r => {
        const courseNum = ConvertService.toNumber(r.courseCode.match(/\d+/)[0]);
        subjects.push({
          courseId: courseNum,
          ...r
        })
      })
      this.db.fetchAdmission(studentKey).valueChanges().subscribe(docs => {
      })
    })
  }

  @action
  fetchCourseInvalidGrade(studentKey, admissionKey) {
    this.loading = true;
    this.db.transcriptFireRef(studentKey, admissionKey).valueChanges().subscribe(snapshots => {
      const items = snapshots.filter(m => m.isShow && m.status.key === 1 && m.credits_earned === 0);
      const result = MappingService.uniqOnTop(items, "courseCode", "page_key");
      this.data = result;
      const t = MappingService.groupBy(result, "term.key", "term.startDateKey");
      this.studentTerm = t;
      this.empty = result.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchData(schoolKey, field) {
    this.loading = true;
    this.ds.studentRef(schoolKey, field).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.data = docs;
      this.loading = false;
    });
  }

  @action
  search(schoolKey, campushKey, field, search) {
    if (search.key) {
      return this.ds.studentSearchRef(schoolKey, campushKey, field, search.puc_id).valueChanges();
    }
    return this.ds.studentSearchRef(schoolKey, campushKey, field, search).valueChanges();
  }

  // @action
  // fetchData(studentKey, admissionKey, status: number) {
  //   this.loading = true;
  //   this.ds.transcriptFireRef(studentKey, admissionKey).valueChanges().subscribe(snapshots => {
  //     let items = snapshots.filter(m => m.isShow && m.isApproval && m.status.key === status && m.courseCode !== 'TRANA07');
  //     let studentTransfer = snapshots.filter(m => m.courseCode === 'TRANA07');
  //     if (studentTransfer.length > 0) {
  //       studentTransfer = MappingService.orderByDesc(studentTransfer, "page_key");
  //       this.transfer = studentTransfer[0];
  //     }
  //     else this.transfer = null;
  //     // items = items.map(m => ({ ...m, cid: ConvertService.numberOnly(m.courseCode) }))
  //     const tempList = items;
  //     const termCourses = [];
  //     items.forEach(m => {
  //       const rows = tempList.filter(i => i.term.key === m.term.key && m.courseCode === i.courseCode);
  //       if (rows.length > 1) {
  //         const rm = rows.filter(m => m.credits_calculator > 0)
  //         if (rm.length > 0) termCourses.push(rm[0])
  //         else termCourses.push(rows[0])
  //       }
  //       else {
  //         termCourses.push(m);
  //       }
  //     })
  //     // const result = MappingService.uniqOnTop(termCourses, "courseName", "term.startDateKey");
  //     // this.data = result;
  //     // if (this.data.length > 0) {
  //     //   const orderData = MappingService.orderBy(this.data, 'term.startDateKey');
  //     //   this.admissionDate = orderData[0].term.startterm;
  //     // }
  //     const result = MappingService.orderBy(termCourses, "term.startDateKey");
  //     this.data = result;

  //     const subjectSEarned = result.filter(m => m.overall_grade !== 'IP' && m.overall_grade !== 'I' && m.overall_grade !== 'W');
  //     this.creditAttempt = MappingService.sumCreditAttempted(result);
  //     const subjectEarned = subjectSEarned;
  //     this.totalCredit = MappingService.sumCreditCalculator(subjectEarned);
  //     this.creditEarned = MappingService.sumCreditEarned(subjectEarned);
  //     const subjectTotalPoint = subjectEarned.map(item => { return { total: item.gradePoint * item.credits_calculator } });
  //     this.totalPoint = MappingService.sumTotalPoint(subjectTotalPoint);
  //     this.totalGPA = this.totalPoint / this.totalCredit;
  //     const t = MappingService.groupBy(result, "term.key", "term.startDateKey");
  //     this.studentTerm = t;
  //     this.empty = result.length === 0;
  //     this.loading = false;
  //   })
  // }

  @action
  saveStudent(studentKey: string, admission: Array<any>, f: any, user, callback) {
    const batch = this.ds.batch();
    this.process = true;
    const studentRef = this.ds.studentFireRef().doc(studentKey);
    const admissionRef = this.ds.admissionFireRef();
    batch.update(studentRef, f);
    admission.forEach(item => {
      batch.update(admissionRef.doc(item.key), f)
    });
    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  saveStudentTransfer(studentKey: string, admission: any, item: any, callback) {
    const batch = this.ds.batch();
    this.loading = true;

    const studentTranscriptRef = this.ds.studentTranscriptFire().doc(studentKey).collection("admission").doc(admission.key).collection("courses").doc(item.key);
    const studentTransferRef = this.ds.studentTransferFire().doc(item.key);
    batch.set(studentTranscriptRef, item);
    batch.set(studentTransferRef, item);

    batch.commit().then(() => {
      this.loading = false;
      callback(true, null)
    }).catch(error => {
      this.loading = false;
      callback(false, error)
    })
  }

  @action
  updateEducations(studentKey: string, admissionKey: string, f: any, callback) {
    const batch = this.ds.batch();
    this.process = true;
    const studentRef = this.ds.studentFireRef().doc(studentKey);
    const admissionRef = this.ds.studentAdmissionTranscriptFire(studentKey).doc(admissionKey);
    const studentAdmissionRef = this.ds.studentTranscriptFire().doc(studentKey).collection("admission").doc(admissionKey);
    const majorAdmissionRef = this.ds.admissionFireRef().doc(admissionKey);

    batch.update(studentAdmissionRef, f)
    batch.update(studentRef, f);
    batch.update(majorAdmissionRef, f);
    batch.update(admissionRef, f);

    batch.commit().then(() => {
      this.process = false;
      callback(true, null)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  studentDoc(any, callback) {
    this.process = true;
    this.ds
      .studentDoc(any)
      .valueChanges()
      .subscribe(docs => {
        this.process = false;
        callback(docs);
      });
  }

  @action
  fetchTerm() {
    this.process = true;
    this.ds
      .termRef()
      .valueChanges()
      .subscribe(docs => {
        this.term = docs;
        this.process = false;
      });
  }

  // @action
  // fetchStudent(key: string, callback) {
  //   this.process = true;
  //   this.ds
  //     .studentDoc(key)
  //     .valueChanges()
  //     .subscribe(doc => {
  //       this.student = doc;
  //       const { creditRef } = doc;
  //       this.creditNote = ConvertService.toNull(creditRef);
  //       this.process = false;
  //       callback(doc);
  //     });
  // }

  @action
  async fetchAcademicYear() {
    let selectedAcademicYear = null;
    const envDoc: any = await this.ds.academicRef().get().toPromise();
    const envData = MappingService.pushToObject(envDoc);
    if (this.selectedAdmission) {
      const { program_academic } = this.selectedAdmission;
      if (program_academic && program_academic.program.programOption.key === 1)
        selectedAcademicYear = envData.year;
      else
        selectedAcademicYear = envData.term;
    }
    return selectedAcademicYear;
  }

  @action
  clearStudentStore() {
    this.student = null;
    this.selectedAdmission = null;
    this.admissions = [];
    this.selectedAcademicYear = null;
  }

  @action
  async fetchStudent(studentKey: string, admissionKey: string, callback?: any) {
    this.clearStudentStore();
    this.loading = true;
    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();
    const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
    const admissionList = await this.ds.studentAllAdmissionRef(studentKey).get().toPromise();
    const studentData = MappingService.pushToObject(studentDoc);
    this.admissions = MappingService.orderByDesc(MappingService.pushToArray(admissionList), "academicYear.startDate");
    this.selectedAdmission = MappingService.pushToObject(admissionDoc)
    this.student = studentData;
    this.selectedAcademicYear = await this.fetchAcademicYear();
    const { prepaid, installment } = studentData;
    this.installment = ConvertService.toNumber(installment);
    this.prepaid = ConvertService.toNumber(prepaid);
    this.loading = false;
    if (callback) callback(studentData)
  }

  @observable fetchScholarshipDataRef: any = null;
  @action
  fetchScholarshipData(studentKey: string) {
    this.loading = true;
    this.fetchScholarshipDataRef = this.ds.scholarshipDBByStudentRef(studentKey)
      .valueChanges()
      .subscribe(doc => {
        this.scholarship = MappingService.orderByDesc(doc, "page_key");
        this.empty = doc.length === 0;
        this.loading = false;
      });
  }

  @action
  fetchStudentTest(testingKey, studentKey) {
    this.loading = true;
    if (testingKey) {
      this.ds.testingStudent(testingKey).valueChanges().subscribe(doc => {
        this.studentTesting = doc;
        this.loading = false;
      })
    }
    else {
      this.ds.studentTestRef(studentKey).valueChanges().subscribe((docs: any) => {
        this.studentTesting = docs.length > 0 ? docs[0] : null;
        this.loading = false;
      })
    }

  }

  @action
  fetchFaculty(callback) {
    this.process = true;
    this.db
      .facultiesDocs()
      .valueChanges()
      .subscribe(docs => {
        callback(docs);
        this.process = false;
      });
  }

  @action
  fetchMajor(facultyKey: string, callback) {
    this.process = true;
    this.db
      .majorDocs(facultyKey)
      .valueChanges()
      .subscribe(docs => {
        callback(docs);
        this.process = false;
      });
  }

  @action
  fetchCountry(callback) {
    this.process = true;
    this.db.countryRef()
      .valueChanges()
      .subscribe(docs => {
        callback(docs);
        this.process = false;
      });
  }

  @action
  fetchProvince(callback) {
    this.process = true;
    this.db.provinceRef()
      .valueChanges()
      .subscribe(docs => {
        callback(docs);
        this.process = false;
      });
  }

  @action
  fetchUniversity(callback) {
    this.process = true;
    this.db.universitiesRef()
      .valueChanges()
      .subscribe(docs => {
        callback(docs);
        this.process = false;
      });
  }

  @action
  fetchNationality(callback) {
    this.process = true;
    this.db.nationalityRef()
      .valueChanges()
      .subscribe(docs => {
        this.process = false;
        callback(docs);
      });
  }

  @action
  fetchScholarship(studentKey, scholarshipKey, callback) {
    this.process = true;
    this.db.scholarshipRef(studentKey, scholarshipKey).valueChanges().subscribe(docs => {
      callback(docs.length === 0 ? null : docs[0]);
      this.process = false;
    })
  }

  // --------------------------------------------------------------------------------------------------------

  lastVisible: any = null;
  currentDate: Date = null;
  infinite: Observable<any[]>;

  @observable public filter = null;
  @observable public orderBy = null;
  @observable public searchText = null;
  @observable public fetching: boolean = false;
  @observable fetchPermissionRecords: Subscription = null;

  infiniteData(data: any) {
    this.infinite = of(data).pipe(distinct((p: any) => p.key));
  }

  @action
  async fetchStudents(search: any, filter: any, orderBy: any) {
    this.orderBy = orderBy;
    this.searchText = search;
    this.filter = filter;
    this.loading = true;
    this.fetching = false;
    this.lastVisible = null;

    this.fetchPermissionRecords && this.fetchPermissionRecords.unsubscribe();
    const ref = this.lazyDataRef(this.lastVisible, search, filter, orderBy);
    this.fetchPermissionRecords = ref.snapshotChanges().subscribe(async (response: any) => {
      this.data = [];
      this.currentDate = this.currentDate || new Date();

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }

      await Promise.all(response.map(async (item) => {
        const studentData = item.payload.doc.data() as any;

        if (!studentData?.program_academic?.admissionKey) {
          this.data.push({
            ...studentData,
            studentAdmissionData: null,
            admissionAttendanceStatistic: null
          });
          return;
        }

        const studentAdmissionData = pushToObject(await this.afs.collection('academics_major_admission').doc(studentData.program_academic.admissionKey).get().toPromise());
        const admissionAttendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission').doc(studentData.program_academic.admissionKey)
          .collection('admission_attendance_statistic', ref => ref.where('create_date_key', '==', toDateKey(this.currentDate))).get().toPromise());
        this.data.push({ ...studentData, studentAdmissionData, admissionAttendanceStatistic });
        // console.log('admissionAttendanceStatistic', admissionAttendanceStatistic);

      }));


      this.data = this.data.map((f: any, index: number) => ({
        ...f, rowIndex: index + 1
      }));

      this.lastVisible = this.data[this.data.length - 1];
      this.loading = false;
      this.fetching = false;
      this.infiniteData(this.data);
      return true;
    }, (error: any) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchMoreStudents() {
    this.fetching = true;

    this.lazyDataRef(this.lastVisible, this.searchText, this.filter, this.orderBy).get().subscribe(async response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      await Promise.all(response.docs.map(async (item) => {
        const studentData = item.data() as any;
        if (!studentData?.program_academic) return studentData;
        const studentAdmissionData = pushToObject(await this.afs.collection('academics_major_admission').doc(studentData.program_academic.admissionKey).get().toPromise());
        const admissionAttendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission').doc(studentData.program_academic.admissionKey)
          .collection('admission_attendance_statistic', ref => ref.where('create_date_key', '==', toDateKey(this.currentDate))).get().toPromise());
        this.data.push({ ...studentData, studentAdmissionData, admissionAttendanceStatistic });
      }));

      this.data = this.data.map((f: any, index: number) => ({
        ...f, rowIndex: index + 1
      }));

      this.lastVisible = this.data[this.data.length - 1];
      this.fetching = false;
      this.infiniteData(this.data);

      return;
    }, (error: any) => {
      this.fetching = false;
    });
  }

  lazyDataRef(lastVisible: any, search: any, filter: any, orderBy: any) {
    return this.afs.collection("students", ref => {
      let condition = ref.limit(Pages.size);
      if (search && search.search) condition = condition.where('puc_id', '==', search.search);
      else condition = condition.orderBy("puc_id");
      if (lastVisible) condition = condition.startAfter(lastVisible["puc_id"])
      return condition;
    });
  }

  // --

  @action
  async fetchStudents_withFilterPolicyRemark(search: any, filter: any, orderBy: any) {
    this.orderBy = orderBy;
    this.searchText = search;
    this.filter = filter;
    this.loading = true;
    this.fetching = false;
    this.lastVisible = null;

    this.fetchPermissionRecords && this.fetchPermissionRecords.unsubscribe();
    const ref = this.lazyDataRef_withFilterPolicyRemark(this.lastVisible, search, filter, orderBy);
    this.fetchPermissionRecords = ref.snapshotChanges().subscribe(async (response: any) => {
      this.data = [];
      this.infinite = of([]);
      this.currentDate = this.currentDate || new Date();

      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }

      await Promise.all(response.map(async (item) => {
        const data = item.payload.doc.data() as any;
        const studentAdmissionData = pushToObject(await this.afs.collection('academics_major_admission').doc(item.payload.doc.ref.parent.parent.id).get().toPromise());
        if (!studentAdmissionData) return null;
        const studentData = pushToObject(await this.afs.collection('students').doc(studentAdmissionData.studentKey).get().toPromise())
        // const admissionAttendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission').doc(studentData.program_academic.admissionKey)
        //   .collection('admission_attendance_statistic', ref => ref.where('create_date_key', '==', data.create_date_key)).get().toPromise());
        this.data.push({ ...studentData, studentAdmissionData, admissionAttendanceStatistic: [data] });
      }));

      this.data = this.data.filter((item) => item).map((f: any, index: number) => ({
        ...f, rowIndex: index + 1
      }));

      this.lastVisible = response[response.length - 1].payload.doc.data();
      this.loading = false;
      this.fetching = false;
      this.infiniteData(this.data);
      return true;
    }, (error: any) => {
      console.log('error', error);
      this.loading = false;
    });
  }

  @action
  async fetchMoreStudents_withFilterPolicyRemark() {
    this.fetching = true;

    this.lazyDataRef_withFilterPolicyRemark(this.lastVisible, this.searchText, this.filter, this.orderBy).get().subscribe(async response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }

      await Promise.all(response.docs.map(async (item) => {
        const data = item.data();
        const studentAdmissionData = pushToObject(await this.afs.collection('academics_major_admission').doc(item.ref.parent.parent.id).get().toPromise());
        const studentData = pushToObject(await this.afs.collection('students').doc(studentAdmissionData.studentKey || studentAdmissionData.student.key).get().toPromise())
        // const admissionAttendanceStatistic = pushToArray(await this.afs.collection('academics_major_admission').doc(studentData.program_academic.admissionKey)
        //   .collection('admission_attendance_statistic', ref => ref.where('create_date_key', '==', data.create_date_key)).get().toPromise());
        this.data.push({ ...studentData, studentAdmissionData, admissionAttendanceStatistic: [data] });
      }));

      this.data = this.data.map((f: any, index: number) => ({
        ...f, rowIndex: index + 1
      }));

      this.lastVisible = response.docs[response.docs.length - 1].data();
      this.fetching = false;
      this.infiniteData(this.data);

      return;
    }, (error: any) => {
      this.fetching = false;
    });
  }

  lazyDataRef_withFilterPolicyRemark(lastVisible: any, search: any, filter: any, orderBy: any) {
    return this.afs.collectionGroup("admission_attendance_statistic", ref => {
      let condition = ref.limit(Pages.size).orderBy("date");
      if (filter && filter.policyRemark) condition = condition.where('remark.key', '==', filter.policyRemark.key);
      if (this.currentDate) condition = condition.where('create_date_key', '==', toDateKey(this.currentDate));
      if (lastVisible) condition = condition.startAfter(lastVisible["date"])
      return condition;
    });
  }

  @action
  async fetchStudentPolicyRemarks() {
    try {
      const policyRemarks = pushToArray(await this.ds.storeRef().collection('policy_remarks').get().toPromise());
      return policyRemarks;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async fetchStudentShiftPolicyHours() {
    try {
      const policyRemarks = pushToArray(await this.ds.collectionRef('academics_policy_hour').get().toPromise());
      return policyRemarks;
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async addStudentAttendanceMovemeent(studentData: any, policyRemark: any, date: any, callback?: (isSuccess, error) => void) {
    this.process = true;

    const batch = this.ds.batch();
    const { key: studentKey, studentAdmissionData, admissionAttendanceStatistic } = studentData;

    const currentSession = { fromHoursNumber: toHourMM(new Date()), fromHours: toTimeHourMMA(new Date()) }
    const policyHours = pushToArray(await this.ds.schoolFireDocRef().collection("time_and_attendances_policy").where("status.key", "==", 1).get());
    const shiftPolicyHours = pushToArray(await this.ds.collectionRef('academics_policy_hour').get().toPromise());
    const sysConfig = pushToObject(await this.ds.schoolFireDocRef().collection("sys_option").doc("general").get());
    const presentRemark = sysConfig.attendanceP;

    const onConfirmTimeAttendanceDate = date ? date : new Date()
    const dateString = moment(onConfirmTimeAttendanceDate).format("YYYYMMDD");

    const { fromHours } = currentSession;
    const dateTime = moment(`${dateString}-${fromHours}`, "YYYYMMDD-hh:mm:ss A").toDate();
    const createDate = dateTime;

    const currentPolicyHour = policyHours.filter(p => p.fromHoursNumber <= currentSession.fromHoursNumber && p.toHoursNumber >= currentSession.fromHoursNumber)[0];
    const selectedPolicyHour = { fromHour: currentPolicyHour.shift.fromHour_, toHour: currentPolicyHour.shift.toHour_ };
    const presentCheckInInShift_policyHours = policyHours.filter((policyHour) => policyHour.shift.key === currentPolicyHour.shift.key
      && policyHour.remark.key === presentRemark.key && policyHour.type.key === ATTENDANCE_TYPE[0].key);
    const presentCheckInInShift_policyHour = presentCheckInInShift_policyHours.length > 0 ? presentCheckInInShift_policyHours[0] : null;
    const studentTimeAttendances = pushToArray(await this.ds.studentFireRef().doc(studentData.key).collection("time_attendance").where('create_date_key', '==', toDateKey(createDate)).get());

    let timeAttendance = studentTimeAttendances.length > 0 ? studentTimeAttendances[0] : null;
    let remark = attendantRemarkObj(policyRemark);
    let remarkKey = policyRemark.key;

    const { policyHourShift } = admissionAttendanceStatistic[0]
    const selected_shiftPolicyHours = shiftPolicyHours.find(m => m.key === policyHourShift.key)
    if (!timeAttendance) {
      const studentKey = studentData.key;
      const timeAttendanceKey = this.ds.createId();
      const studentTimeAttendance = this.ds.studentFireRef().doc(studentKey).collection("time_attendance").doc(timeAttendanceKey);
      const storeTimeAttendance = this.ds.schoolFireDocRef().collection("time_attendance").doc(timeAttendanceKey);

      remark = attendantRemarkObj(policyRemark);
      remarkKey = policyRemark.key;

      timeAttendance = {
        key: timeAttendanceKey,
        scan_card_key: null,
        create_type: CREATE_TIME_TYPE.administrator,

        create_by: userObj(this.auth.user),
        create_date: createDate,
        create_date_key: toDateKey(createDate),

        studentKey: studentKey,
        student: studentObj(studentData),
        page_key:pageKey(),
        campusKey: studentAdmissionData.campuskey || null,
        schoolKey: studentAdmissionData.schoolKey || null,
        type: HR_USER_TYPES.students,

        policy: attendancePolicyObj(presentCheckInInShift_policyHour),
        policyKey: presentCheckInInShift_policyHour.key,
        policy_hour: selectedPolicyHour,
        policy_type: presentCheckInInShift_policyHour.type,
        remark: remark,
        remarkKey: remarkKey,

        scan_date: createDate,
        scan_date_key: toDateKey(createDate),
        confirm_date: new Date(),
        confirm_by: this.auth.user.key,
        sessionKey: null,
      };

      batch.set(studentTimeAttendance, timeAttendance);
      batch.set(storeTimeAttendance, timeAttendance);

      const attendanceKey = this.ds.createId();
      const studentAttendanceMovementRef_store = this.ds.schoolFireDocRef().collection("student_attendance_movement").doc(attendanceKey);
      const studentAttendanceMovementRef = this.ds.studentFireRef().doc(studentData.key).collection("student_attendance_movement").doc(attendanceKey);

      const sub_selectedPolicyHour = { fromHour: selected_shiftPolicyHours.fromHour_, toHour: selected_shiftPolicyHours.toHour_ };
      const sub_currentSession = { fromHoursNumber: selected_shiftPolicyHours.fromHour_, fromHours: selected_shiftPolicyHours.fromHour };
      const sub_presentCheckInInShift_policyHour = policyHours.find((policyHour) => policyHour.shift.key === selected_shiftPolicyHours.key
        && policyHour.remark.key === presentRemark.key && policyHour.type.key === ATTENDANCE_TYPE[0].key);

      const attendenceMovement: any = {
        key: attendanceKey,
        studentKey: studentKey,
        campusKey: studentAdmissionData.campusKey || null,
        schoolKey: studentAdmissionData.schoolKey || null,
        batchKey: studentAdmissionData.batchKey || null,
        timeAttendanceKey: timeAttendance.key || null,
        status: { key: 1, text: "Active" },
        student: studentObj(studentData),

        create_by: userObj(this.auth.user),
        create_date: createDate,
        create_date_key: toDateKey(createDate),
        create_date_month_key: toMonthKey(createDate),

        admissionKey: studentData.program_academic.admissionKey,
        academicYearKey: studentAdmissionData.academicYearKey,
        programKey: studentAdmissionData.trainingProgramKey,
        levelKey: studentAdmissionData.trainingGradeKey,
        scheduleKey: null,
        schedule_subjectKey: null,
        schedule_subject: null,
        checkIn_date: createDate,
        checkIn_date_key: toDateKey(createDate),

        policy: attendancePolicyObj(sub_presentCheckInInShift_policyHour.policy),
        policyKey: sub_presentCheckInInShift_policyHour.key || "",
        policy_hour: sub_selectedPolicyHour,
        policy_type: sub_presentCheckInInShift_policyHour.type || null,
        remark: remark || null,
        remarkKey: remarkKey || null,

        type: HR_USER_TYPES.students,
        note: timeAttendance && timeAttendance.note || null,

        session: sub_currentSession,
        sessionKey: null,
        confirm_date: new Date(),
        confirm_by: this.auth.user.key,

        prioritizedPageKey: pageKey(),
        prioritizedShift: selected_shiftPolicyHours,
        isPrioritizedMovement: true,
        attendance_registration_init: false,
        attendance_registration_source: ATTENDANCE_MOVEMENT_SOURCE.manual_attendance,
        page_key:pageKey(),
      };

      batch.set(studentAttendanceMovementRef_store, attendenceMovement);
      batch.set(studentAttendanceMovementRef, attendenceMovement);

    } else {
      if (admissionAttendanceStatistic && admissionAttendanceStatistic.length > 0) {

        const attendanceKey = this.ds.createId();
        const studentAttendanceMovementRef_store = this.ds.schoolFireDocRef().collection("student_attendance_movement").doc(attendanceKey);
        const studentAttendanceMovementRef = this.ds.studentFireRef().doc(studentData.key).collection("student_attendance_movement").doc(attendanceKey);

        const sub_selectedPolicyHour = { fromHour: selected_shiftPolicyHours.fromHour_, toHour: selected_shiftPolicyHours.toHour_ };
        const sub_currentSession = { fromHoursNumber: selected_shiftPolicyHours.fromHour_, fromHours: selected_shiftPolicyHours.fromHour };
        const sub_presentCheckInInShift_policyHour = policyHours.find((policyHour) => policyHour.shift.key === selected_shiftPolicyHours.key
          && policyHour.remark.key === presentRemark.key && policyHour.type.key === ATTENDANCE_TYPE[0].key);

        const attendenceMovement: any = {
          key: attendanceKey,
          studentKey: studentKey,
          campusKey: studentAdmissionData.campusKey || null,
          schoolKey: studentAdmissionData.schoolKey || null,
          batchKey: studentAdmissionData.batchKey || null,
          timeAttendanceKey: timeAttendance.key || null,
          status: { key: 1, text: "Active" },
          student: studentObj(studentData),
          page_key:pageKey(),
          create_by: userObj(this.auth.user),
          create_date: createDate,
          create_date_key: toDateKey(createDate),
          create_date_month_key: toMonthKey(createDate),

          admissionKey: studentData.program_academic.admissionKey,
          academicYearKey: studentAdmissionData.academicYearKey,
          programKey: studentAdmissionData.trainingProgramKey,
          levelKey: studentAdmissionData.trainingGradeKey,
          scheduleKey: null,
          schedule_subjectKey: null,
          schedule_subject: null,
          checkIn_date: createDate,
          checkIn_date_key: toDateKey(createDate),

          policy: attendancePolicyObj(sub_presentCheckInInShift_policyHour.policy),
          policyKey: sub_presentCheckInInShift_policyHour.key || "",
          policy_hour: sub_selectedPolicyHour,
          policy_type: sub_presentCheckInInShift_policyHour.type || null,
          remark: remark || null,
          remarkKey: remarkKey || null,

          type: HR_USER_TYPES.students,
          note: timeAttendance && timeAttendance.note || null,

          session: sub_currentSession,
          sessionKey: null,
          confirm_date: new Date(),
          confirm_by: this.auth.user.key,

          isPrioritizedMovement: true,
          prioritizedShift: selected_shiftPolicyHours,
          prioritizedPageKey: pageKey(),
          attendance_registration_init: false,
          attendance_registration_source: ATTENDANCE_MOVEMENT_SOURCE.manual_attendance
        };

        batch.set(studentAttendanceMovementRef_store, attendenceMovement);
        batch.set(studentAttendanceMovementRef, attendenceMovement);
      }


      // shiftPolicyHours.map((item) => {
      //   const attendanceKey = this.ds.createId();
      //   const studentAttendanceMovementRef_store = this.ds.schoolFireDocRef().collection("student_attendance_movement").doc(attendanceKey);
      //   const studentAttendanceMovementRef = this.ds.studentFireRef().doc(studentData.key).collection("student_attendance_movement").doc(attendanceKey);

      //   const sub_selectedPolicyHour = { fromHour: item.fromHour_, toHour: item.toHour_ };
      //   const sub_currentSession = { fromHoursNumber: item.fromHour_, fromHours: item.fromHour };
      //   const sub_presentCheckInInShift_policyHour = policyHours.find((policyHour) => policyHour.shift.key === item.key
      //     && policyHour.remark.key === presentRemark.key && policyHour.type.key === ATTENDANCE_TYPE[0].key);

      //   const attendenceMovement: any = {
      //     key: attendanceKey,
      //     studentKey: studentKey,
      //     campusKey: studentAdmissionData.campusKey || null,
      //     schoolKey: studentAdmissionData.schoolKey || null,
      //     batchKey: studentAdmissionData.batchKey || null,
      //     timeAttendanceKey: timeAttendance.key || null,
      //     status: { key: 1, text: "Active" },
      //     student: studentObj(studentData),

      //     create_by: userObj(this.auth.user),
      //     create_date: createDate,
      //     create_date_key: toDateKey(createDate),
      //     create_date_month_key: toMonthKey(createDate),

      //     admissionKey: studentData.program_academic.admissionKey,
      //     academicYearKey: studentAdmissionData.academicYearKey,
      //     programKey: studentAdmissionData.trainingProgramKey,
      //     levelKey: studentAdmissionData.trainingGradeKey,
      //     scheduleKey: null,
      //     schedule_subjectKey: null,
      //     schedule_subject: null,
      //     checkIn_date: createDate,
      //     checkIn_date_key: toDateKey(createDate),

      //     policy: attendancePolicyObj(sub_presentCheckInInShift_policyHour.policy),
      //     policyKey: sub_presentCheckInInShift_policyHour.key || "",
      //     policy_hour: sub_selectedPolicyHour,
      //     policy_type: sub_presentCheckInInShift_policyHour.type || null,
      //     remark: remark || null,
      //     remarkKey: remarkKey || null,

      //     type: HR_USER_TYPES.students,
      //     note: timeAttendance && timeAttendance.note || null,

      //     session: sub_currentSession,
      //     sessionKey: null,
      //     confirm_date: new Date(),
      //     confirm_by: this.auth.user.key,

      //     isPrioritizedMovement: true,
      //     prioritizedShift: item,
      //     prioritizedPageKey: pageKey(),
      //     attendance_registration_init: false,
      //     attendance_registration_source: ATTENDANCE_MOVEMENT_SOURCE.manual_attendance
      //   };

      //   batch.set(studentAttendanceMovementRef_store, attendenceMovement);
      //   batch.set(studentAttendanceMovementRef, attendenceMovement);
      // });
    }
    this.process = false;


    await batch.commit().then(() => {
      callback(true, null);
      this.process = false;
    }).catch(error => {
      this.process = false;
      callback(false, error);
    });
  }

}
