import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateAuditDescription'
})
export class GenerateAuditDescriptionPipe implements PipeTransform {

  transform(item: any): string {
    let strResult = `${item?.instructor?.display_name}`;
    if(item.audit_type.text === 'create')
      strResult += ` created "${item?.course_title}" course.`;
    else if(item.audit_type.text === 'update')
      strResult += ` updated "${item?.course_title}" course.`;
    return strResult;
  }

}
