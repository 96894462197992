


import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { REQUEST_STATUS, scholarStatus } from 'src/app/core/dummy/stauts';
import { Environment } from 'src/app/core/stores/environment';
import { InstituteEnrollment } from 'src/app/core/stores/institute-enrollment.store';
import { userObj } from 'src/app/shared/services/mapping.service';

@Component({
  selector: 'app-reject-request',
  templateUrl: './reject-request.component.html',
  styleUrls: ['./reject-request.component.scss']
})
export class RejectRequestComponent implements OnInit {
  @ViewChild("focusInput") inputEl: ElementRef;

  form: FormGroup;
  reason: AbstractControl;
  from_date: AbstractControl;
  to_date: AbstractControl;

  process = false;
  filteredSchoolProgramStates: Observable<any[]>;

  scholarStatusList: any = scholarStatus;
  START_UPLOAD: boolean = false;
  uploadPercent: any;
  uploadedFiles: any[] = [];
  selectedFile: any[] = [];

  remarkLists;
  constructor(
    public dialogRef: MatDialogRef<RejectRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: InstituteEnrollment,
    public env: Environment,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      reject_reason: [null, Validators.required]
    })
    const { user } = this.env;
    this.store.fetchPolicyRemark(user.schoolKey, data => {
      this.remarkLists = data;
    })
  }
  compareObjects(o1: any, o2: any): boolean {
    if (o2) { return o1.key === o2.key; }
  }

  create(formData) {
    this.dialogRef.close(formData)
  }
}
