import { Pipe, PipeTransform } from '@angular/core';
import { fileSize } from '../services/mapping.service';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  
  transform(value: any, args?: any): any {
    if(value) return fileSize(value)
    return null;
  }


}
