import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'identifiedSelectionOfPermissibleSessionCard',
})
export class IdentifiedSelectionOfPermissibleSessionCardPipe implements PipeTransform {

  transform(selectedSessions: any, session: any, schedule: any, relatedSchedule: any): string {
    const permission_date_key = relatedSchedule.date;
    const instructorKey = schedule.instructor.key;
    const scheduleSubjectKey = schedule.schedule_subject.key;
    const sessionName = session.name.replace(' ', '');
    const key = `${instructorKey}_${scheduleSubjectKey}_${permission_date_key}_${sessionName}`;
    const isSelected = selectedSessions.some((item) => item.key === key);
    return isSelected ? 'selected' : 'normal';
  }

}
