import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from 'src/app/core/services/data.service';
import { MappingService, pushToObject } from '../services/mapping.service';

@Pipe({
  name: 'totalEnroll'
})
export class TotalEnrollPipe implements PipeTransform {
  total = 0;
  constructor(private ds:DataService){}
  async transform(batchKey): Promise<any> {
    if(!batchKey) return null;
    const data:any = pushToObject(await this.ds.batchLevelRef().doc(batchKey).get().toPromise())

    return data.total_enroll
    // return MappingService.orderBy(item.filter(m=>m.term && m.term.key===args),"courseCode");
  }

}
