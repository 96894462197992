import { Pipe, PipeTransform } from '@angular/core';
import { MappingService } from '../services/mapping.service';

@Pipe({
  name: 'filterSchoolFee'
})
export class FilterSchoolFeePipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    return MappingService.orderByDesc(values.filter(m => m.paymentOption && m.paymentOption.key === args), "fee.name");
  }

}
