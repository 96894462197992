import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cashier } from 'src/app/core/stores/cashier.store';
import { Environment } from 'src/app/core/stores/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-payment-modal',
  templateUrl: './confirm-payment-modal.component.html',
  styleUrls: ['./confirm-payment-modal.component.scss']
})
export class ConfirmPaymentModalComponent implements OnInit {
  process = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmPaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Cashier,
    public env: Environment,
    private snackBar: MatSnackBar,
    private router: Router,

  ) { }

  ngOnInit() {
  }
  create(){
    if(this.env.user){
      this.store.ConfirmPaymentComponent(this.data, this.env.user, success=>{
        if(success){
          this.dialogRef.close();
          this.snackBar.open('Payment confirm successfully', 'done', { duration: 2000 });
          this.router.navigate(['/']);
        }else{
          this.snackBar.open('Confirm Payment fail!', 'Error')
        }
      })
    }
  }

}
