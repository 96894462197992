import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { invoiceTypesObj } from '../dummy/stauts';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService {

  constructor(private ds: DataService,
    private db: AngularFirestore
    ) { }

  updatePucIdAndInvoice(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        puc_id: setting.puc_id + 1,
        invoice_shufit: setting.invoice_shufit + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }
  transcriptFireRef(studentKey:string,admissionKey:string) {
    return this.db.collection<any>("academic_student_transcript")
    .doc(studentKey)
    .collection("admission")
    .doc(admissionKey)
    .collection<any>("courses");
  }
  studentDoc(key) {
    return this.db.collection('students').doc<any>(key)
  }
  batchLevelRef() {
    return this.db.collection("institute_training_level_batch")
  }
  studentCurrentScheduleRef(termKey, studentKey) {
    return this.db
      .collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("issue_term.key", "==", termKey)
      .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
      .where("isHeader", "==", false)
      .where("isVoid", "==", false)
      );
  }
  academicTermDocRef() {
    return this.db.collection("academics_term");
  }
  majorDocs(facultyKey) {
    return this.db.collection('majors',ref=>ref.where("faculty.key","==",facultyKey));
  }
  countryRef(){
    return this.db.collection("location_countries",ref=>ref.orderBy("name"))
  }

  provinceRef(){
    return this.db.collection("location_cities",ref=>ref.orderBy("name"))
  }

  universitiesRef(){
    return this.db.collection("universities",ref=>ref.orderBy("name"))
  }


  nationalityRef(){
    return this.db.collection("nationality",ref=>ref.orderBy("name"))
  }
  
  scholarshipRef(studentKey,scholarshipKey){
    return this.db.collection("students").doc(studentKey).collection("scholarships",ref=>ref.where("program.key","==",scholarshipKey))
  }
  termRef() {
    return this.db.collection("academics_term", ref =>
      ref.orderBy("startDateKey", "desc").limit(5)
    );
  }
  facultiesDocs() {
    return this.db.collection('faculties');
  }

  transcriptCourseRef(studentKey,admissionKey,courseKey){
    return this.db.collection("academic_student_transcript").doc(studentKey)
    .collection("admission").doc(admissionKey).collection("courses").doc(courseKey);
  }
  admissionRef(){
    return this.db.collection("academics_major_admission")
  }
  transcriptStudentRef(pucCode:string) {
    return this.db.collection<any>("academic_enrollment",ref=>ref
    .where("studentCode","==",pucCode)
    .orderBy("TermID")
    .orderBy("program.key")
    );
  }
  fetchAdmission(studentKey){
    return this.db.collection<any>('academics_major_admission',ref=>ref
    .where("student.key","==",studentKey)
    .where("status.key","==",1)
    // .orderBy('term.startDateKey',"desc")
    );
  }
  updateSerialIdAndInvoice(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        serial_number: setting.serial_number + 1,
        invoice_shufit: setting.invoice_shufit + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updateSerialId(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        serial_number: setting.serial_number + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updatePucId(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        puc_id: setting.puc_id + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  updateInvoiceNo(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        invoice_shufit: setting.invoice_shufit + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }
  
  updateInvoiceNoAndPucID(puc_id,callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      if(puc_id){
        this.ds.sysSetting().update({
          invoice_shufit: setting.invoice_shufit + 1,
        }).then(() => {
          callback(true, setting)
        }).catch(error => {
          callback(false, error)
        })
      }
      else{
        this.ds.sysSetting().update({
          invoice_shufit: setting.invoice_shufit + 1,
          puc_id: setting.puc_id + 1,
        }).then(() => {
          callback(true, setting)
        }).catch(error => {
          callback(false, error)
        })
      }
    }).catch(error => {
      callback(false, error)
    })
  }

  updatePucIdAndSerialID(callback) {
    this.ds.settingFireStore().get().then(config => {
      const setting = config.data();
      this.ds.sysSetting().update({
        puc_id: setting.puc_id + 1,
        serial_number: setting.serial_number + 1,
      }).then(() => {
        callback(true, setting)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

}
