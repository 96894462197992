export const InvoiceTypes = [
    { key: 0, text: "All Payments", link: 'payments', router: "payments", icon: "local_mall" },
    { key: 1, text: "Testing Fee", link: 'testing-fee', router: "testing-fee", icon: "playlist_add_check" },
    { key: 2, text: "Tuition Fee", link: 'tuition-fee', router: "tuition-fee", icon: "school" },
    { key: 3, text: "Short Course", link: 'short-course', router: "short-course", icon: "whatshot" },
    { key: 4, text: "ABC", link: 'abc', router: "abc", icon: "spa" },
    { key: 5, text: "SPEL", link: 'spel', router: "spel", icon: "spellcheck" },
    { key: 6, text: "Miscellaneous", link: 'miscellaneous', router: "miscellaneous", icon: "category" },
];

export const payments = [
    // { path: '/payment-listing/all', label: 'All',icon:"ballot" },
    // { path: '/payment-listing/tuition_fee', label: 'Tuition Fee',icon:"how_to_reg" },
    // { path: '/payment-listing/academic_program', label: 'Academic',icon:"school" },
    // { path: '/payment-listing/institute_and_center', label: 'Institute',icon:"local_library" },
    { path: '/payment-listing/', slabel: '/testing/program', label: 'Testing', icon: "playlist_add_check" },
    { path: '/payment-listing/', slabel: '/abc', label: 'ABC', icon: "location_on" },
    { path: '/payment-listing/', slabel: '/miscellaneous/program', label: 'Miscellaneous', icon: "local_offer" },
    { path: '/payment-listing/', slabel: '/short_course', label: 'Short Course', icon: "phone_iphone" },
];


export const paymentsCampus = [
    // { path: '/payment-listing/all', label: 'All',icon:"ballot" },
    // { path: '/payment-listing/tuition_fee', label: 'Tuition Fee',icon:"how_to_reg" },
    // { path: '/payment-listing/academic_program', label: 'Academic',icon:"school" },
    // { path: '/payment-listing/institute_and_center', label: 'Institute',icon:"local_library" },
    { path: '/payment-campus/', slabel: '/testing/program', label: 'Testing', icon: "playlist_add_check" },
    { path: '/payment-campus/', slabel: '/abc', label: 'ABC', icon: "location_on" },
    { path: '/payment-campus/', slabel: '/miscellaneous/program', label: 'Miscellaneous', icon: "local_offer" },
    { path: '/payment-campus/', slabel: '/short_course', label: 'Short Course', icon: "phone_iphone" },
];
export const reportFilterBy = [
    { key: 'today', text: 'Today' },
    { key: 'yesterday', text: 'Yesterday' },
    { key: 'this_week', text: 'This week' },
    { key: 'this_month', text: 'This month' },
    // { key: 'this_term', text: 'This term' },
    { key: 'this_year', text: 'This year' },
    { key: 'custom', text: 'Custom' },
];

// export const PHOTO_LIB = {
//     EMPTY: 'https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c'
// }

export const STATUS_OBJ = {
    DELETED: { key: 0, text: 'Deleted' },
    ACTIVE: { key: 1, text: 'Active' },
    DISABLED: { key: 2, text: 'Disabled' },
    COMPLETED: { key: 3, text: 'Completed' },
}

export const MOVEMENT_STATUS_OBJ = {
    HOLD: { key: 0, text: 'Hold' },
    RELEASED: { key: 1, text: 'Released' },
    VOID: { key: 2, text: 'Void' },
}

export const MOVEMENT_STATUS_DATA = {
    draft: { key: 0, text: 'Hold' },
    submitted: { key: 1, text: 'Released' },
}

export const UNIT_OPERATOR = [
    { key: 1, operator: '*', name: 'Multiply' },
    { key: 2, operator: '/', name: 'Divide' },
]

export const ADJUSTMENT_TYPES = [
    { key: 1, IR: 'I', name: 'Opening Balance', factor: 1, short: 'OB' },
    { key: 2, IR: 'I', name: 'Increase Stock', short: 'ADJ+', factor: 1 },
    // { key: 3, IR: 'I', name: 'Transfer+', factor: 1 },
    { key: 4, IR: 'R', name: 'Reduce Stock', short: 'ADJ-', factor: -1 },
    // { key: 5, IR: 'R', name: 'Transfer-', factor: -1 },
    // { key: 6, IR: 'I', name: 'Bill', short: 'BILL', factor: 1 },
]

export const NAME_TYPES = [
    { key: 1, name: 'Customer', ref: 'customers' },
    { key: 2, name: 'Suppliers', ref: 'suppliers' },
    { key: 3, name: 'Employee', ref: 'employees' },
]

export const ADJUSTMENT_TYPE_OBJECT = {
    OB: { key: 1, IR: 'I', name: 'Opening Balance', factor: 1, short: 'OB' },
    ADJ_I: { key: 2, IR: 'I', name: 'Increase Stock', short: 'ADJ+', factor: 1 },
    TRAN_IN: { key: 3, IR: 'I', name: 'Transfer+', short: 'T-IN', factor: 1 },
    ADJ_R: { key: 4, IR: 'R', name: 'Reduce Stock', short: 'ADJ-', factor: -1 },
    TRAN_OUT: { key: 5, IR: 'R', name: 'Transfer-', short: 'T-OUT', factor: -1 },
    BILL: { key: 6, IR: 'I', name: 'Bill', short: 'BILL', factor: 1 },
}

export const LINE_TYPE_OBJECT = {
    SPECIAL: { key: 0, name: 'Special' },
    ITEM: { key: 1, name: 'Item' },
}


export const DISCOUNT_TYPE_OPTIONS = [
    { key: 0, name: 'Percent (%)' },
    { key: 1, name: 'Cash ($)' },
]

export const PURCHASE_TYPE_OPTIONS = [
    { key: 0, name: 'Item' },
    { key: 1, name: 'Free' },
]


export const GRADING_SYSTEM_TYPE = {
    'monthly-grade': { key: 1, route: 'monthly-grade', name: 'ពិន្ទុប្រចាំខែ', enName: 'Monthly Grade' },
    'semester-grade': { key: 2, route: 'semester-grade', name: 'ពិន្ទុឆមាសទី I', enName: 'Semester I' },
    'year-grade': { key: 4, route: 'year-grade', name: 'ពិន្ទុប្រចាំឆ្នាំ', enName: 'Annual Grade' },
}

export const GRADING_SYSTEM_TYPE_ARRAY = [
    { key: 1, name: 'ពិន្ទុប្រចាំខែ', enName: 'Monthly Grade' },
    { key: 2, name: 'ពិន្ទុឆមាសទី I', enName: 'Semester I' },
    { key: 3, name: 'ពិន្ទុឆមាសទី II', enName: 'Semester II' },
]

export const chatBoxColor = {
  //first color top, second color bottom
  a: ['#ffcd6a', '#ffa85c'],

  b: ['#ff885e', '#ff516a'],
  ព: ['#ff885e', '#ff516a'],
  ប: ['#ff885e', '#ff516a'],

  c: ['#82b1ff', '#665fff'],
  ច: ['#82b1ff', '#665fff'],
  ជ: ['#82b1ff', '#665fff'],

  d: ['#a0de7e', '#54cb68'],
  ដ: ['#a0de7e', '#54cb68'],
  ឌ: ['#a0de7e', '#54cb68'],

  e: ['#72d5fd', '#2a9ef1'],

  f: ['#53edd6', '#28c9b7'],
  ថ: ['#53edd6', '#28c9b7'],
  ធ: ['#53edd6', '#28c9b7'],

  g: ['#e0a2f3', '#d669ed'],
  ង: ['#e0a2f3', '#d669ed'],
  អ: ['#e0a2f3', '#d669ed'],

  h: ['#ffcd6a', '#ffa85c'],
  ហ: ['#ffcd6a', '#ffa85c'],

  i: ['#ff885e', '#ff516a'],

  j: ['#82b1ff', '#665fff'],
  ញ: ['#82b1ff', '#665fff'],

  k: ['#a0de7e', '#54cb68'],
  ក: ['#a0de7e', '#54cb68'],
  គ: ['#a0de7e', '#54cb68'],

  l: ['#72d5fd', '#2a9ef1'],
  ល: ['#72d5fd', '#2a9ef1'],
  ឡ: ['#72d5fd', '#2a9ef1'],

  m: ['#53edd6', '#28c9b7'],
  ម: ['#53edd6', '#28c9b7'],

  n: ['#e0a2f3', '#d669ed'],
  ន: ['#e0a2f3', '#d669ed'],
  ណ: ['#e0a2f3', '#d669ed'],

  o: ['#ffcd6a', '#ffa85c'],

  p: ['#ff885e', '#ff516a'],
  ផ: ['#ff885e', '#ff516a'],
  ភ: ['#ff885e', '#ff516a'],

  q: ['#82b1ff', '#665fff'],
  ឆ: ['#82b1ff', '#665fff'],
  ឈ: ['#82b1ff', '#665fff'],

  r: ['#a0de7e', '#54cb68'],
  រ: ['#a0de7e', '#54cb68'],
  ឬ: ['#a0de7e', '#54cb68'],

  s: ['#72d5fd', '#2a9ef1'],
  ស: ['#72d5fd', '#2a9ef1'],

  t: ['#53edd6', '#28c9b7'],
  ទ: ['#53edd6', '#28c9b7'],
  ត: ['#53edd6', '#28c9b7'],

  u: ['#e0a2f3', '#d669ed'],

  v: ['#ffcd6a', '#ffa85c'],
  វ: ['#ffcd6a', '#ffa85c'],

  w: ['#ff885e', '#ff516a'],

  x: ['#82b1ff', '#665fff'],
  ខ: ['#82b1ff', '#665fff'],
  ឃ: ['#82b1ff', '#665fff'],

  y: ['#a0de7e', '#54cb68'],
  យ: ['#a0de7e', '#54cb68'],

  z: ['#72d5fd', '#2a9ef1'],
  ឋ: ['#72d5fd', '#2a9ef1'],
  ឍ: ['#72d5fd', '#2a9ef1'],
  ឯ: ['#72d5fd', '#2a9ef1'],
}
