import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-puc-button',
  templateUrl: './puc-button.component.html',
  styleUrls: ['./puc-button.component.scss']
})
export class PucButtonComponent implements OnInit {
  @Input() class: any;
  @Input() process: boolean;
  @Input() disabled:boolean;
  @Input() label: string;
  @Output() onPress = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  _onPress() {
    this.onPress.emit();
  }

}
