import { DataService } from './../services/data.service';
import { observable, computed, action, autorun, toJS } from 'mobx';
import { Injectable } from '@angular/core';
import { allocatedStatusObj } from '../dummy/stauts';
import { MappingService } from 'src/app/shared/services/mapping.service';
import { ConvertService } from 'src/app/shared/services/convert.service';

@Injectable({ providedIn: 'root' })
export class Cashier {
  @observable paymentData = [];
  @observable data: any = [];
  @observable cashierData: any = [];
  @observable payments: any = [];
  @observable dataDoc = null;
  @observable onlinedata: any = [];
  @observable loading = false;
  @observable process = false;
  @observable grandTotal = 0;
  @observable public confirmPayments = [];
  @observable public SelectedconfirmPayments = null;
  public lastVisible: any = null;
  @observable public fetching: boolean = false;
  @observable public searchText = null;
  @observable public filter = null;
  constructor(private ds: DataService) {
  }

  @action
  fetchConfirmPayment(schoolKey) {
    this.loading = true;
    this.ds
      .shiftMovementSummaryRef()
      .valueChanges()
      .subscribe(docs => {
        this.confirmPayments = docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey);
        this.loading = false;
      });
  }

  @action
  fetchConfirmPaymentCallBack(schoolKey,callback) {
    this.loading = true;
    this.ds
      .shiftMovementSummaryRef()
      .valueChanges()
      .subscribe(docs => {
        this.confirmPayments = docs.filter((m:any)=>m.schoolKey && m.schoolKey === schoolKey);
        this.loading = false;
        callback(docs)
      });
  }

  @action
  fetchConfirmPaymentDoc(doc, callback) {
    this.loading = true;
    this.ds
      .shiftMovementSummaryDocRef(doc)
      .valueChanges()
      .subscribe(docs => {
        this.SelectedconfirmPayments = docs;
        callback(docs);
        this.loading = false;
      });
  }

  @action
  fetchCashierDoc(key, callback) {
    this.loading = true;
    this.ds
      .cashierDocRef(key)
      .valueChanges()
      .subscribe(docs => {
        this.dataDoc = docs;
        callback(docs);
        this.loading = false;
      });
  }


  @action
  fetchCashierInvoice(uid, invoiceType: number, fromDate: number, toDate: number) {
    this.loading = true;
    this.paymentData = [];
    this.ds
      .cashierInvoiceRef(uid, invoiceType, fromDate, toDate)
      .valueChanges()
      .subscribe(docs => {
        this.paymentData = docs;
        this.payments = docs.filter(m => m.isHeader === true);
        this.grandTotal = MappingService.sum(this.payments, "amount");
        this.loading = false;
      });
  }

  @action
  fetchCashier() {
    this.loading = true;
    this.ds
      .cashierRef()
      .valueChanges()
      .subscribe(docs => {
        this.data = docs;
        this.loading = false;
      });
  }


  @action
  fetchCashierOnline(schoolKey: string) {
    this.loading = true;
    this.ds.cashierOnlineRef().valueChanges().subscribe(docs => {
      this.onlinedata = docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey);
      this.loading = false;
    });
  }

  @action
  ConfirmPaymentComponent(item: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const data = {
      allocated_status: allocatedStatusObj.complete,
      confirm_by: user,
      confirm_date: new Date,
      confirm_date_key: ConvertService.dateKey(),
    }
    const movement = this.ds.shiftMovementFirRef().doc(item.shift.key);
    const sumary = this.ds.shiftMovementSummaryFirRef().doc(item.key);
    batch.update(sumary, data)
    batch.update(movement, data)
    batch
      .commit()
      .then(() => {
        callback(true, item);
        this.process = false;
      })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

  @action
  fetchData() {
    this.loading = true;
    this.ds.cashierRef().valueChanges().subscribe(docs => {
      this.data = docs;
      this.loading = false;
    });
  }

  @action
  async fetchDataFireUser(search: any, filter: any) {
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;
    this.searchText = search;
    this.filter = filter;
    const ref = this.ds.lazyUserRef(this.lastVisible, search, filter)
    ref.auditTrail().subscribe();
    ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.loading = false
      this.fetching = false;
    }, error => {
      this.loading = false;
    });
  }

  @action
  async fetchDataFireUserMore() {
    this.fetching = true;
    this.ds.lazyUserRef(this.lastVisible, this.searchText, this.filter).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      this.fetching = false;
    }, error => {
      this.fetching = false;
    });
  }

  @action
  async fetchDataFireCashier(search: any, filter: any) {
    this.loading = true;
    this.lastVisible = null;
    this.fetching = false;
    this.searchText = search;
    this.filter = filter;
    const ref = this.ds.lazyCashierRef(this.lastVisible, search, filter)
    ref.auditTrail().subscribe();
    ref.snapshotChanges().subscribe(response => {
      this.data = [];
      if (!response.length) {
        this.loading = false;
        this.fetching = false;
        return false;
      }
      this.lastVisible = response[response.length - 1].payload.doc;
      for (let item of response) {
        this.data.push(item.payload.doc.data());
      }
      this.loading = false
      this.fetching = false;
    }, error => {
      this.loading = false;
    });
  }

  @action
  async fetchDataFireCashierMore() {
    this.fetching = true;
    this.ds.lazyCashierRef(this.lastVisible, this.searchText, this.filter).get().subscribe(response => {
      if (!response.docs.length) {
        this.loading = false
        this.fetching = false;
        return;
      }
      this.lastVisible = response.docs[response.docs.length - 1];
      for (let item of response.docs) {
        this.data.push(item.data());
      }
      this.fetching = false;
    }, error => {
      this.fetching = false;
    });
  }
  @action
  fetchAllData() {
    this.loading = true;
    this.ds.getCashierDocRef().valueChanges().subscribe(docs => {
      this.data = docs.filter((m: any) => !m.isCashier);
      this.loading = false;
    });
  }
  @action
  delete(item: any, callback) {
    this.process = true;
    this.ds.getCashierDocRef().doc(item.key).delete().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }
  @action
  async resetStudentPassword(item: any, user: any, callback) {
    this.process = true;
    this.ds.userDocRef(item.key).update({
      resetPassword: true,
      resetPassword_date: new Date(),
      resetPassword_date_key: ConvertService.dateKey(),
      resetPassword_by: MappingService.userObj(user),
    }).then(async () => {

      const studentAccount = await this.ds.userDBRef().doc(item.key).get().toPromise();
      const studentData = MappingService.pushToObject(studentAccount);
      this.process = false;
      callback(true, studentData)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }
}
