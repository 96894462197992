import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterShift'
})
export class FilterShiftPipe implements PipeTransform {

  transform(values: Array<any>, args?: any, field?: any): any {
    if (field) {
      return values.filter(m => m[field] && m[field].key === args);
    }
    return values.filter(m => m.shift && m.shift.key === args);
  }

}
