<aside class="sidebar">
  <div class="sidebar-brand" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
    <div class="sbar-brand-name">
      {{appName?.name}}
    </div>
  </div>
  <div class="sidebar-header">
    <div class="header-content">
      <div class="list-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
        <div class="icon">
          <i class="material-icons">home</i>
        </div>
        <div class="item-name">
          Home
        </div>
      </div>
      <div class="sitting" [matMenuTriggerFor]="menuSitting">
        <i class="material-icons">&#xE8B8;</i>
      </div>
      <mat-menu #menuSitting="matMenu" class="cs-position flr">
        <div class="menu-content">
          <!-- <button mat-menu-item (click)="btnScanCardClicked()">
            <mat-icon>contact_mail</mat-icon>
            <span>Scan Card: {{scanCardStatus ? 'ON' : 'OFF'}}</span>
          </button> -->

          <button mat-menu-item [routerLink]="['/finance-report/accounting/settings/general']">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
  <div class="sidebar-list-wrapper">
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="expansions.one" (closed)="onClosed(1)" (opened)="onOpened(1)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.one">
          <mat-panel-title>
            Administrator
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.one">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/attendence/student']">
              <div class="icon">
                <i class="material-icons-outlined">person_search</i>
              </div>
              <div class="item-name">
                Students
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/student-attendance-dashboard/all']">
              <div class="icon">
                <i class="material-icons-outlined">fact_check</i>
              </div>
              <div class="item-name">
                Student Attendance
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/attendence/permission']">
              <div class="icon">
                <i class="material-icons-outlined">home_repair_service</i>
              </div>
              <div class="item-name">
                Stud. Permission List
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/revoke-student-permission']">
              <div class="icon">
                <i class="material-icons-outlined">delete_sweep</i>
              </div>
              <div class="item-name">
                Revoke Stud. Permission
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/request-change-attendance']">
              <div class="icon">
                <i class="material-icons-outlined">cached</i>
              </div>
              <div class="item-name">
                Request Change Attendance
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/confirm-student-attendance']">
              <div class="icon">
                <i class="material-icons-outlined">verified</i>
              </div>
              <div class="item-name">
                Confirm Stud. Attendance
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/current-student-attendance']">
              <div class="icon">
                <i class="material-icons">supervised_user_circle</i>
              </div>
              <div class="item-name">
                Current Att. Students
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/attendence/student-scan-card']">
              <div class="icon">
                <i class="material-icons-outlined">
                  credit_card
                </i>
              </div>
              <div class="item-name">
                Student Scan Card
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/student-scan-qr-code']">
              <div class="icon">
                <i class="material-icons-outlined">
                  qr_code_2
                </i>
              </div>
              <div class="item-name">
                Student Scan QR-Code
              </div>
            </div>

            <ng-container *ngIf="hasAttendanceStatManagement">
              <div class="list-item-row" routerLinkActive="active"
                [routerLink]="['/admin/attendence/most-absent-students-report']">
                <div class="icon">
                  <i class="material-icons">event_busy</i>
                </div>
                <div class="item-name">
                  Most-Absent Report
                </div>
              </div>

              <div class="list-item-row" routerLinkActive="active"
                [routerLink]="['/admin/attendence/set-attendance-statistic-manually']">
                <div class="icon">
                  <i class="material-icons-outlined">
                    edit_calendar
                  </i>
                </div>
                <div class="item-name">
                  {{ 'Set Att. Statistic Manually' | shorten : '20' : '...' }}
                </div>
              </div>

              <div class="list-item-row" routerLinkActive="active"
                [routerLink]="['/admin/attendence/export-and-import-attendance']">
                <div class="icon">
                  <i class="material-icons-outlined">
                    file_upload
                  </i>
                </div>
                <div class="item-name">
                  Export and Import Att.
                </div>
              </div>

              <div class="list-item-row" routerLinkActive="active"
                [routerLink]="['/admin/attendence/attendance-confirmation-dashboard']">
                <div class="icon">
                  <i class="material-icons-outlined">
                    pending_actions
                  </i>
                </div>
                <div class="item-name">
                  Attendance Confirm...
                </div>
              </div>

              <!-- <div class="list-item-row" routerLinkActive="active"
                [routerLink]="['/admin/attendence/resolved-firestore-data-to-alloyDB']">
                <div class="icon">
                  <i class="material-icons-outlined">
                    backup
                  </i>
                </div>
                <div class="item-name">
                  {{ 'Resolved Firestore Data to AlloyDB' | shorten : '20' : '...' }}
                </div>
              </div> -->
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.two">
          <mat-panel-title>
            Instructors & Employees
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.two">Instructor Attendance, Permission, Course Audit
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/employee-attendance-dashboard/all']">
              <div class="icon">
                <i class="material-icons-outlined">
                  badge
                </i>
              </div>
              <div class="item-name">
                Employee Attendance
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/instructor-permission-dashboard/all']">
              <div class="icon">
                <i class="material-icons-outlined">
                  free_cancellation
                </i>
              </div>
              <div class="item-name">
                Emp. Permission List
              </div>
            </div>

            <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/attendence/revoke-employee-permission']">
              <div class="icon">
                <i class="material-icons-outlined">delete_sweep</i>
              </div>
              <div class="item-name">
                Revoke Emp. Permission
              </div>
            </div> -->

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/employee-scan-card']">
              <div class="icon">
                <i class="material-icons-outlined">
                  credit_card
                </i>
              </div>
              <div class="item-name">
                Emp. Scan Card
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/employee-scan-qr-code']">
              <div class="icon">
                <i class="material-icons-outlined">
                  qr_code_2
                </i>
              </div>
              <div class="item-name">
                Emp. Scan QR-Code
              </div>
            </div>

            <div *ngIf="hasAttendanceStatManagement" class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/employee-time-sheet']">
              <div class="icon">
                <i class="material-icons-outlined">
                  badge
                </i>
              </div>
              <div class="item-name">
                Employee Time sheet
              </div>
            </div>

            <!-- <div *ngIf="hasAttendanceStatManagement" class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/attendence-employee']">
              <div class="icon">
                <i class="material-icons-outlined">
                  badge
                </i>
              </div>
              <div class="item-name">
                Attendence Employee
              </div>
            </div> -->


            <div *ngIf="hasAttendanceStatManagement && isAdmin" class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/employee-attendance-statistic']">
              <div class="icon">
                <i class="material-icons-outlined">
                  badge
                </i>
              </div>
              <div class="item-name">
                Employee Attendance Statistic
              </div>
            </div>


            <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/instructors/lesson-plan-list']">
              <div class="icon">
                <i class="material-icons-outlined">
                  analytics
                </i>
              </div>
              <div class="item-name">
                Lesson Plan List
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/instructors/lesson-plan-audit-dashboard']">
              <div class="icon">
                <i class="material-icons-outlined">
                  add_chart
                </i>
              </div>
              <div class="item-name">
                Lesson Plan Audit Dash.
              </div>
            </div> -->
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.three" (closed)="onClosed(3)" (opened)="onOpened(3)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.two">
          <mat-panel-title>
            Print QR Code
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.three">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/students-qrcode']">
              <div class="icon">
                <i class="material-icons">center_focus_weak</i>
              </div>
              <div class="item-name">
                Student QR Code
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/parents-qrcode']">
              <div class="icon">
                <i class="material-icons">center_focus_weak</i>
              </div>
              <div class="item-name">
                Parent QR Code
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/employees-qrcode']">
              <div class="icon">
                <i class="material-icons">center_focus_weak</i>
              </div>
              <div class="item-name">
                Employee QR Code
              </div>
            </div>


          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.four" (closed)="onClosed(4)" (opened)="onOpened(4)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.one">
          <mat-panel-title>
            Reports
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.four">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/reports/student-attendance']">
              <div class="icon">
                <i class="material-icons-outlined">
                  pending_actions
                </i>
              </div>
              <div class="item-name">
                Daily Report
              </div>
            </a>
          </div>
          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/reports/student-attendance-report']">
              <div class="icon">
                <i class="material-icons-outlined">
                  fact_check
                </i>
              </div>
              <div class="item-name">
                Custom Report
              </div>
            </a>
          </div>

          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/reports/student-attendance-static-report']">
              <div class="icon">
                <i class="material-icons-outlined">
                  analytics
                </i>
              </div>
              <div class="item-name">
                Monthly Report
              </div>
            </a>
          </div>

          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/reports/student-attendance-yearly-report']">
              <div class="icon">
                <i class="material-icons-outlined">
                  today
                </i>
              </div>
              <div class="item-name">
                Yearly Report
              </div>
            </a>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.five" (closed)="onClosed(5)" (opened)="onOpened(5)"
        *ngIf="hasCardPrinting">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.three">
          <mat-panel-title>
            Card
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.five">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/student/cardExcel']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Excel Student Card
              </div>
            </div> -->

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/student/card-by-batch']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Batch Student Card
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/student/card-by-batch-employee']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Batch Employee Card
              </div>
            </div>

            <div *ngIf="hasCardPrinting && !school.isPSIS" class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/student/card']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Student Card
              </div>
            </div>

            <ng-container *ngIf="hasCardPrinting && school.isPSIS">
              <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/student/card-kge']">
                <div class="icon">
                  <i class="material-icons">recent_actors</i>
                </div>
                <div class="item-name">
                  KGE Student Card
                </div>
              </div>
              <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/student/card-puc-ifl']">
                <div class="icon">
                  <i class="material-icons">recent_actors</i>
                </div>
                <div class="item-name">
                  PUC-IFL Student Card
                </div>
              </div>
            </ng-container>



            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/instructor/card']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Employee Card
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/card']">
              <div class="icon">
                <i class="material-icons">gesture</i>
              </div>
              <div class="item-name">
                Signature Card
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="sidebar-footer">
    <div class="sidebar-toggle-button" (click)="onToggleSidebar()">
      <div class="arrow-icon"></div>
    </div>
  </div>
</aside>
