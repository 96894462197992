import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report } from 'src/app/core/stores/report.store';
import { PrintService } from 'src/app/shared/services/print.service';

@Component({
  selector: 'app-report-receipt-summary-dialog',
  templateUrl: './report-receipt-summary-dialog.component.html',
  styleUrls: ['./report-receipt-summary-dialog.component.scss']
})
export class ReportReceiptSummaryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReportReceiptSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Report,
    private ps: PrintService,
  ) { }

  ngOnInit() {
    this.store.fetchSummary(this.data);
  }

  print() {
    this.ps.print("#printD", "a4");
  }

}
