import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import { invoiceTypesObj, paymentType, PettyCash_STATUS } from '../dummy/stauts';
import { MappingService, pushToArray } from 'src/app/shared/services/mapping.service';
import { ConvertService } from 'src/app/shared/services/convert.service';
import { ApiService } from '../services/api.service';

@Injectable({ providedIn: 'root' })
export class Report {
  @observable public groupTerm = [];
  @observable public groupProgram = [];
  @observable public groupOtherFee = [];
  @observable public data = [];
  @observable public dataHeader = [];
  @observable public students = [];
  @observable public studentClass = null;
  @observable public dataSource = [];
  @observable public installmentData = [];
  @observable public group = [];
  @observable public penalty = [];
  @observable public scholarship = [];
  @observable public prepaid = [];
  @observable public pettyCash = [];
  @observable public drawer = [];
  @observable public report1Data = [];

  @observable public programTypes = null;
  @observable public programGroup = null;
  @observable public cashIn = null;
  @observable public dailyShiftPayment = null;
  @observable public dailyCampusPayment = null;
  @observable public dailyCampusGroup = null;
  @observable public dailyShiftPaymentCampus = null;
  @observable public installmentPayment = null;
  @observable public expensePayment = null;
  @observable public matchTerm = null;
  @observable public receiptPayment = null;
  @observable public receiptByTerm = [];
  @observable public receiptSummary = null;
  @observable public groupDate = null;
  @observable public groupCashier = null;
  @observable public unpaid = null;

  @observable public totalAmount = null;
  @observable public moneyRiels = null;
  @observable public moneyDollars = null;

  @observable public loading = false;
  @observable public process = false;
  @observable public empty = false;
  @observable public totalLine = 0;
  @observable public balance = 0;
  @observable public balanceClose = 0;
  @observable public totaldailyShiftPaymentCampus = 0;
  @observable public reportName: string;

  @observable public totalAmountFix1 = null;

  constructor(
    private ds: DataService,
    public api: ApiService
  ) {

  }

  @action
  async fetchReport1(queryParams) {
    this.loading = true;
    this.report1Data = []
    const {
      branchKey,
      departmentKey,
      employmentTypeKey,
      positionKey,
      salaryFrom,
      salaryTo,
    } = queryParams;


    let link = `${this.api.baseUri}fetchReportingEmployeeContract`;

    if (salaryFrom && salaryTo) {
      link += `?salaryFrom=${salaryFrom}&salaryTo=${salaryTo}`
    } else {
      link += `?salaryFrom=0&salaryTo=100000`
    }

    if (branchKey) {
      link += `&branchKey=${branchKey}`
    }

    if (departmentKey) {
      link += `&departmentKey=${departmentKey}`
    }

    if (positionKey) {
      link += `&positionKey=${positionKey}`
    }

    if (employmentTypeKey) {
      link += `&employmentTypeKey=${employmentTypeKey}`
    }

    this.api.get(link).then(async (res) => {
      if (res && res.data) {
        this.report1Data = res.data;
      }
      this.loading = false;
    });
  }
  // @action
  // fetchSummeryByShift(shiftKey, uid) {
  //   this.loading = true;
  //   this.ds
  //     .invoiceShiftRef(shiftKey, uid)
  //     .valueChanges()
  //     .subscribe(docs => {
  //       const rows = docs.filter(m => m.isHeader === false);
  //       this.data = rows;
  //       this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
  //       this.totalLine = this.group.length;
  //       this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
  //       this.scholarship = docs.filter(m => m.scholarship && m.isHeader === true);
  //       this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
  //       this.empty = docs.length === 0;
  //       this.fetchOpenBalanceShift(shiftKey, list => {
  //         this.fetchPettyCash(shiftKey, petty => {
  //           this.fetchInstallment(shiftKey, install => {
  //             this.loading = false;
  //           })
  //         })
  //       })
  //     });
  // }
  @action
  async fetchPosition() {
    this.process = true;
    const docs = await this.ds.positionRef().get().toPromise();
    this.process = false;
    return pushToArray(docs);
  }
  @action
  async fetchDepartment() {
    this.process = true;
    const docs = await this.ds.departmentsRef().get().toPromise();
    this.process = false;
    return pushToArray(docs);
  }

  @action
  saveReceiptAllCash(shiftKey: string, riels: Array<any>, dollars: Array<any>, callback) {
    this.loading = true;
    const batch = this.ds.batch();
    const shiftRef = this.ds.shiftMovementFireRef().doc(shiftKey);

    riels.forEach(riel => {
      batch.set(shiftRef.collection("riels").doc(riel.key), riel);
    });

    dollars.forEach(dollar => {
      batch.set(shiftRef.collection("dollars").doc(dollar.key), dollar);
    });

    batch.update(shiftRef, { confirmCash: true })

    batch.commit().then(() => {
      this.loading = false;
      callback(true, null);
    }).catch(error => {
      this.loading = false;
      callback(false, error);
    })
  }

  @action
  async fetchCampus(storeKey) {
    this.loading = true;
    const data = await this.ds.campusStoreRef(storeKey).get().toPromise();
    let docs = MappingService.pushToArray(data);
    docs = MappingService.orderBy(docs, "order");
    this.loading = false;
    return docs;
  }
  @action
  fetchWingEPayment(fromDate: number, toDate: number) {
    this.loading = true;
    this.ds.receiptWingEPaymentRef(fromDate, toDate).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.receiptPayment = MappingService.orderByDesc(docs, "invoice_no");
      this.loading = false;
    })
  }


  @action
  fetchWingMerchantEPayment(fromDate: number, toDate: number, campusKey: string) {
    this.loading = true;
    if (campusKey) {
      this.ds.shiftMovementRef().doc("wing").collection('campus').doc(campusKey).collection("invoices", ref => ref
        .where("payment_type.key", '==', paymentType.wingMerchant.key)
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")
      ).valueChanges().subscribe(docs => {
        this.empty = docs.length === 0;
        this.receiptPayment = MappingService.orderByDesc(docs, "received_date");
        this.loading = false;
      })
    }
    else {
      this.ds.shiftMovementRef().doc("wing").collection("invoices", ref => ref
        .where("payment_type.key", '==', paymentType.wingMerchant.key)
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")
      ).valueChanges().subscribe(docs => {
        this.empty = docs.length === 0;
        this.receiptPayment = MappingService.orderByDesc(docs, "received_date");
        this.loading = false;
      })
    }

  }
  @action
  fetchABAEPayment(fromDate: number, toDate: number, campusKey: string) {
    this.loading = true;
    if (campusKey) {
      this.ds.shiftMovementRef().doc("aba").collection('campus').doc(campusKey).collection("invoices", ref => ref
        .where("payment_type.key", '==', paymentType.abaBilling.key)
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")
      ).valueChanges().subscribe(docs => {
        this.empty = docs.length === 0;
        this.receiptPayment = MappingService.orderByDesc(docs, "received_date");
        this.loading = false;
      })
    } else {
      this.ds.shiftMovementRef().doc("aba").collection("invoices", ref => ref
        .where("payment_type.key", '==', paymentType.abaBilling.key)
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")
      ).valueChanges().subscribe(docs => {
        this.empty = docs.length === 0;
        this.receiptPayment = MappingService.orderByDesc(docs, "received_date");
        this.loading = false;
      })
    }

  }

  @action
  fetchACLEDAEPayment(fromDate: number, toDate: number, campusKey: string) {
    this.loading = true;
    if (campusKey) {
      this.ds.shiftMovementRef().doc("acleda").collection('campus').doc(campusKey).collection("invoices", ref => ref
        .where("payment_type.key", '==', paymentType.acledaBilling.key)
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")
      ).valueChanges().subscribe(docs => {
        this.empty = docs.length === 0;
        this.receiptPayment = MappingService.orderByDesc(docs, "received_date");
        this.loading = false;
      })
    } else {
      this.ds.shiftMovementRef().doc("acleda").collection("invoices", ref => ref
        .where("payment_type.key", '==', paymentType.acledaBilling.key)
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
        .orderBy("received_date_key", "desc")
      ).valueChanges().subscribe(docs => {
        this.empty = docs.length === 0;
        this.receiptPayment = MappingService.orderByDesc(docs, "received_date");
        this.loading = false;
      })
    }

  }

  @action
  async fetchTermReporting() {
    this.loading = true;
    const data = await this.ds.termAcademicReportRef().get().toPromise();
    return MappingService.pushToArray(data)
  }

  @action
  async fetchCashierReporting() {
    this.loading = true;
    const data = await this.ds.cashierRef().get().toPromise();
    let docs = MappingService.pushToArray(data);
    docs = MappingService.orderBy(docs, "campus.name");
    docs = docs.filter(m => m.status.key === 1 && m.email !== "sitha.b@puc.edu.kh");
    return docs;
  }

  @action
  fetchTermArray(callback) {
    this.ds.termAcademicRef().valueChanges().subscribe(docs => {
      callback(docs)
    })
  }

  @action
  fetchUnpaid(term) {
    this.loading = true;
    this.ds.termAcademicRef().doc(term.key).collection("credit_note").valueChanges().subscribe(docs => {
      this.unpaid = docs;
      this.loading = false;
    })
  }

  @action
  fetchCashierArray(callback) {
    this.ds.cashierRef().valueChanges().subscribe(docs => {
      let data: Array<any> = docs;
      let cashier = data.filter(m => m.email !== "sitha.b@puc.edu.kh");
      callback(cashier)
    })
  }

  @action
  fetchDailyShiftListing(fromDate: number, toDate: number, campusKey: string, schoolKey: string, roleKey: number, user: any) {
    const { memberOf } = user;
    this.loading = true;
    this.dailyShiftPayment = []
    this.ds.dailyShiftPaymentRef(fromDate, toDate, campusKey).valueChanges().subscribe(docs => {
      const dailyCampusPaymentData = roleKey === 0 ?
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey) :
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey && m.campusKey === user.campusKey);
      this.empty = dailyCampusPaymentData.length === 0;
      if (dailyCampusPaymentData.length > 0) {
        if (memberOf.key == 2) {
          let data = dailyCampusPaymentData.filter((f: any) => f.create_by.campus.key === user.campusKey)
          this.dailyShiftPayment.push(...data);
        } else {
          this.dailyShiftPayment = MappingService.orderByDesc(dailyCampusPaymentData, "create_by.campus.key");
        }
      }
      this.loading = false;
    })
  }

  @action
  async fetchDailyTestingListing(fromDate: any, toDate: any, campusKey: string, programKey: string) {
    this.loading = true;
    const data = pushToArray(await this.ds.dailyTestingRef(fromDate, toDate, campusKey, programKey).get().toPromise())
    this.data = data.filter(f => f.programLevel);
    this.loading = false;
  }


  @action
  async fetchDailyScholarshipListing(fromDate: any, toDate: any, campusKey: string, programKey: string) {
    this.loading = true;
    const data = pushToArray(await this.ds.scholarshipsListingRef(fromDate, toDate, campusKey, programKey).get().toPromise())
    this.data = data
    this.loading = false;
  }

  @action
  fetchDailyCampusListing(fromDate: number, toDate: number, campusKey: string, schoolKey: string, roleKey: number, user: any) {
    const { memberOf } = user;
    this.loading = true;
    this.dailyCampusPayment = [];
    this.dailyCampusGroup = [];
    this.ds.dailyShiftPaymentRef(fromDate, toDate, campusKey).valueChanges().subscribe(docs => {
      const dailyCampusPaymentData = roleKey === 0 ?
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey) :
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey && m.campusKey === user.campusKey);

      this.empty = dailyCampusPaymentData.length === 0;
      if (dailyCampusPaymentData.length > 0) {
        if (memberOf.key == 2) {
          let data = dailyCampusPaymentData.filter((f: any) => f.create_by.campus.key === user.campusKey)
          this.dailyCampusPayment.push(...data);
        } else {
          this.dailyCampusPayment = dailyCampusPaymentData;
        }

        this.dailyCampusGroup = MappingService.groupByDesc(this.dailyCampusPayment, "create_by.campus.key", "create_by.campus.key");
      }

      this.loading = false;
    })
  }

  @action
  fetchDailyShiftListingCampus(campuskey, fromDate: number, toDate: number) {
    this.loading = true;
    this.ds.dailyShiftPaymentCampusRef(campuskey, fromDate, toDate).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.dailyShiftPaymentCampus = docs;
      const balance = this.dailyShiftPaymentCampus.reduce((acc, cur) => acc + Number(cur.balance), 0);
      const pretty = this.dailyShiftPaymentCampus.reduce((acc, cur) => acc + Number(cur.petty_cash), 0);
      const installment = this.dailyShiftPaymentCampus.reduce((acc, cur) => acc + Number(cur.installment), 0);

      this.totaldailyShiftPaymentCampus = balance + installment - pretty;
      this.loading = false;
    })
  }

  @action
  fetchExpenseListing(fromDate: number, toDate: number, campusKey: string, schoolKey: string, roleKey: number, user: any) {
    this.loading = true;
    this.expensePayment = [];
    this.ds.expensePaymentRef(fromDate, toDate, campusKey).valueChanges().subscribe(docs => {

      const dailyCampusPaymentData = roleKey === 0 ?
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey) :
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey && m.campusKey === user.campusKey);

      this.empty = dailyCampusPaymentData.length === 0;
      this.expensePayment = dailyCampusPaymentData;
      this.loading = false;
    })
  }

  @action
  fetchMatchTermListing(fromDate: number, toDate: number, campusKey: string, schoolKey: string, roleKey: number, user: any) {
    this.loading = true;
    this.matchTerm = [];
    this.ds.matchTermPaymentRef(fromDate, toDate, campusKey).valueChanges().subscribe(docs => {

      const dailyCampusPaymentData = roleKey === 0 ?
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey) :
        docs.filter((m: any) => m.schoolKey && m.schoolKey === schoolKey && m.campusKey === user.campusKey);

      this.empty = dailyCampusPaymentData.length === 0;
      this.matchTerm = dailyCampusPaymentData;
      this.loading = false;
    })
  }

  @action
  async fetchReceiptReportByTermListing(fromDate: number, toDate: number, termKey: any, cashierKey: any, order: string) {
    this.loading = true;
    this.receiptByTerm = [];
    const data = await this.ds.receiptReportByTermRef(fromDate, toDate, termKey, cashierKey).get().toPromise();
    const docs = MappingService.pushToArray(data);
    this.empty = docs.length === 0;
    this.receiptByTerm = MappingService.orderByDesc(docs, order);
    this.loading = false;
  }

  @action
  fetchReceiptReportListing(fromDate: number, toDate: number, termKey: any, cashierKey: any) {
    this.loading = true;
    this.ds.receiptReportRef(fromDate, toDate, termKey, cashierKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.receiptPayment = MappingService.orderByDesc(docs, "invoice_no");
      this.loading = false;
    })
  }

  @action
  fetchReceiptReportSummaryListing(fromDate: number, toDate: number, termKey: any, cashierKey: any, programKey: any) {
    this.loading = true;
    this.ds.receiptSummaryReportRef(fromDate, toDate, termKey, cashierKey, programKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.receiptSummary = MappingService.orderByDesc(docs, "invoice_no");
      this.groupDate = MappingService.groupByDesc(docs, "received_date_key", "received_date_key");
      this.groupCashier = MappingService.groupByDesc(docs, "received_by.key", "received_by.campus.key");
      this.loading = false;
    })
  }

  @action
  fetchInstallmentListing(fromDate: number, toDate: number) {
    this.loading = true;
    this.installmentPayment = null;
    this.ds.installmentPaymentRef(fromDate, toDate).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.installmentPayment = docs;
      this.loading = false;
    })
  }

  @action
  async fetchSchoolProgram() {
    const docs = await this.ds.schoolProgramRef().get().toPromise();
    return pushToArray(docs)
  }
  @action
  fetchTermReport(callback) {
    this.loading = true;
    this.ds.termReportRef().valueChanges().subscribe(docs => {
      this.loading = false;
      callback(docs)
    });
  }
  // @action
  // fetchOpenBalanceShift(shiftKey: any, callback) {
  //   this.process = true;
  //   this.ds.dailyShiftByUserShiftRef(shiftKey).valueChanges().subscribe(docs => {
  //     this.drawer = docs;
  //     this.process = false;
  //     callback(docs);
  //   })
  // }

  // @action
  // fetchSummary(shift: any) {
  //   this.loading = true;
  //   this.balance = null;
  //   this.ds
  //     .invoiceAllSummeryByShiftRef(shift.key)
  //     .valueChanges()
  //     .subscribe(docs => {
  //       const rowHeader = docs.filter(m => m.isHeader === true);
  //       const rows = docs.filter(m => m.isHeader === false);
  //       this.data = rows;
  //       this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
  //       this.totalLine = this.group.length;
  //       this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
  //       this.scholarship = docs.filter(m => m.scholarshipRef && m.isHeader === true);
  //       this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
  //       this.cashIn = shift.cash_in;
  //       this.empty = docs.length === 0;
  //       const tuitionFee = MappingService.sum(rowHeader, "amount");
  //       this.fetchPettyCash(shift.key, petty => {
  //         this.fetchInstallment(shift.key, install => {
  //           this.loading = false;
  //           this.balance = tuitionFee;
  //           this.balanceClose = tuitionFee + ConvertService.toNumber(shift.cash_in) + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.dataSource, "price");
  //         })
  //       })
  //     });
  // }

  // @action
  // fetchSummeryType(shift: any, invoiceTypeKey: number, type: any) {
  //   this.loading = true;
  //   this.balance = null;
  //   this.ds
  //     .invoiceSummeryByShiftRef(shift.key, invoiceTypeKey, type)
  //     .valueChanges()
  //     .subscribe(docs => {
  //       const rows = docs.filter(m => m.isHeader === false);
  //       const rowHeader = docs.filter(m => m.isHeader === true);
  //       this.data = rows;
  //       this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
  //       this.totalLine = this.group.length;
  //       this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
  //       this.scholarship = docs.filter(m => m.scholarshipRef && m.isHeader === true);
  //       this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
  //       this.empty = docs.length === 0;
  //       const tuitionFee = MappingService.sum(rowHeader, "amount");
  //       this.fetchPettyCash(shift.key, petty => {
  //         this.fetchInstallment(shift.key, install => {
  //           this.loading = false;
  //           this.balance = tuitionFee + ConvertService.toNumber(this.cashIn) + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.dataSource, "price");
  //         })
  //       })
  //     });
  // }

  @action
  fetchPettyCash(shiftKey: string, callback) {
    this.process = true;
    this.ds.pettyCashByShiftRef(shiftKey).valueChanges().subscribe(docs => {
      if (docs && docs.length > 0) {
        this.dataSource = docs.filter((m: any) => m.pettyCash_status.key === PettyCash_STATUS.approved.key)
      }
      this.process = false;
      callback(docs);
    })
  }

  @action
  fetchInstallment(shiftKey: string, callback) {
    this.process = true;
    this.installmentData = [];
    this.ds.installmentByShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.installmentData = docs;
      this.process = false;
      callback(docs);
    })
  }

  @action
  fetchReceiptByTerm(shiftKey: string) {
    this.loading = true;
    this.data = null;
    this.groupTerm = [];
    this.programGroup = [];
    this.pettyCash = [];
    this.totalAmount = null;
    this.ds.invoiceByDetailShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      const tuitionFee = docs.filter(m => m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
      this.groupTerm = MappingService.groupByDesc(tuitionFee, "issue_year.key", "issue_year.page_key");
      const programFee = docs.filter(m => m.invoice_type.key !== invoiceTypesObj.tuitionFee.key && m.invoice_type.key !== invoiceTypesObj.otherFee.key);
      this.groupProgram = MappingService.groupByDesc(programFee, "invoice_type.key", "invoice_type.text");

      const otherFees = docs.filter(m => m.invoice_type.key === invoiceTypesObj.otherFee.key);
      this.groupOtherFee = MappingService.groupByDesc(otherFees, "course.key", "course.key");

      let totalScholarship = MappingService.sum(docs, "scholarship") + MappingService.sum(docs, "loan");
      let totalPenalty = MappingService.sum(docs, "penalty");
      let totalPrepaid = MappingService.sum(docs, "prepaid");
      let totalFee = MappingService.sum(docs, "amount");
      this.fetchPettyCash(shiftKey, petty => {
        this.pettyCash = petty.filter((m: any) => m.pettyCash_status.key === PettyCash_STATUS.approved.key);
        this.fetchInstallment(shiftKey, install => {
          this.installmentData = install;
          const totalPayment = totalFee + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.pettyCash, "price");
          this.totalAmount = ConvertService.toFloatFixed(totalPayment, 2);
          this.loading = false;
        })
      })
    })
  }

  @action
  fetchReceiptListing(shiftKey: string) {
    this.loading = true;
    this.ds.invoiceByShiftRef(shiftKey).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "invoice_no");
      this.programTypes = MappingService.groupBy(
        docs,
        "program.key",
        "program.key"
      );
      this.empty = docs.length === 0;
      this.fetchPettyCash(shiftKey, petty => {
        this.fetchInstallment(shiftKey, install => {
          this.loading = false;
        })
      })
    })
  }

  @action
  async fetchStudentInBatch(batchKey: string) {
    this.loading = true;
    const studentDocs = await this.ds.batchLevelDBRef().doc(batchKey).collection("students").get().toPromise();
    this.students = MappingService.pushToArray(studentDocs)
    const batchDocs = await this.ds.batchLevelDBRef().doc(batchKey).get().toPromise();
    this.studentClass = MappingService.pushToObject(batchDocs)
    this.loading = false;

  }

  @action
  fetchSummary(shift: any) {
    this.loading = true;
    this.balance = null;
    this.ds
      .invoiceAllSummeryByShiftRef(shift.key)
      .valueChanges()
      .subscribe(docs => {
        const rowHeader = docs.filter(m => m.isHeader === true);
        const rows = docs.filter(m => m.isHeader === false);
        this.dataHeader = rowHeader;

        this.data = rows;
        this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
        this.totalLine = this.group.length;
        this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
        this.scholarship = docs.filter(m => m.scholarshipRef && m.isHeader === true);
        this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
        this.cashIn = shift.cash_in;
        this.empty = docs.length === 0;
        const tuitionFee = MappingService.sum(rowHeader, "amount");
        this.fetchPettyCash(shift.key, petty => {
          this.fetchInstallment(shift.key, install => {
            this.loading = false;
            this.balance = tuitionFee;
            this.balanceClose = tuitionFee + ConvertService.toNumber(shift.cash_in) + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.dataSource, "price");
          })
        })
      });
  }

  @action
  fetchReceiptListingCampus(campusKey: string) {
    this.loading = true;
    // this.ds.invoiceByShiftRef(shiftKey).valueChanges().subscribe(docs => {
    //   this.data = MappingService.orderBy(docs, "invoice_no");
    //   this.programTypes = MappingService.groupBy(
    //     docs,
    //     "program.key",
    //     "program.key"
    //   );
    //   this.empty = docs.length === 0;
    //   this.fetchPettyCash(shiftKey, petty => {
    //     this.fetchInstallment(shiftKey, install => {
    //       this.loading = false;
    //     })
    //   })
    // })
  }

  @action
  fetchReceiptTuitionFeeListing(shiftKey: string, type: string) {
    this.loading = true;
    this.ds.invoiceTypeListingByShiftRef(shiftKey, type).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "invoice_no");
      this.programTypes = MappingService.groupBy(
        docs,
        "display_program",
        "display_program"
      );
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchReceiptTypeListing(shiftKey: string, type: string) {
    this.loading = true;
    this.ds.invoiceTypeListingByShiftRef(shiftKey, type).valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "invoice_no");
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  // @action
  // saveReceiptAllCash(shiftKey: string, riels: Array<any>, dollars: Array<any>, callback) {
  //   this.loading = true;
  //   const batch = this.ds.batch();
  //   const shiftRef = this.ds.shiftMovementFireRef().doc(shiftKey);

  //   riels.forEach(riel => {
  //     batch.set(shiftRef.collection("riels").doc(riel.key), riel);
  //   });

  //   dollars.forEach(dollar => {
  //     batch.set(shiftRef.collection("dollars").doc(dollar.key), dollar);
  //   });

  //   batch.update(shiftRef, { confirmCash: true })

  //   batch.commit().then(() => {
  //     this.loading = false;
  //     callback(true, null);
  //   }).catch(error => {
  //     this.loading = false;
  //     callback(false, error);
  //   })
  // }

  @action
  fetchDialShiftCashReceiveRiels(shiftKey: string, callback) {
    this.loading = true;
    this.ds.shiftMovementDocsRef().doc(shiftKey).collection("riels").valueChanges().subscribe(docs => {
      this.moneyRiels = MappingService.orderByDesc(docs, "operant");
      this.loading = false;
      callback(this.moneyRiels);
    })
  }

  @action
  fetchDialShiftCashReceiveDollars(shiftKey: string, callback) {
    this.loading = true;
    this.ds.shiftMovementDocsRef().doc(shiftKey).collection("dollars").valueChanges().subscribe(docs => {
      const data = MappingService.orderByDesc(docs, "operant");
      if (data.length > 0) {
        let checkArray = data.filter(m => m.key === "checkQty");
        let dollarArray = data.filter(m => m.key !== "checkQty");
        this.moneyDollars = dollarArray.concat(checkArray);
      }
      this.loading = false;
      callback(this.moneyRiels);
    })
  }

  @action
  fetchSummeryType(shift: any, invoiceTypeKey: number, type: any) {
    this.loading = true;
    this.balance = null;
    this.ds
      .invoiceSummeryByShiftRef(shift.key, invoiceTypeKey, type)
      .valueChanges()
      .subscribe(docs => {
        const rows = docs.filter(m => m.isHeader === false);
        const rowHeader = docs.filter(m => m.isHeader === true);
        this.data = rows;
        this.group = MappingService.groupBy(rows, "invoice_type.key", "invoice_type.key");
        this.totalLine = this.group.length;
        this.penalty = docs.filter(m => m.penalty && m.isHeader === true);
        this.scholarship = docs.filter(m => m.scholarshipRef && m.isHeader === true);
        this.prepaid = docs.filter(m => m.prepaid && m.isHeader === true);
        this.empty = docs.length === 0;
        const tuitionFee = MappingService.sum(rows, "amount");
        this.fetchPettyCash(shift.key, petty => {
          this.fetchInstallment(shift.key, install => {
            this.loading = false;
            this.balance = tuitionFee + ConvertService.toNumber(this.cashIn) + MappingService.sum(this.installmentData, "price") - MappingService.sum(this.dataSource, "price");
          })
        })
      });
  }

}
