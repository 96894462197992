import { Pipe, PipeTransform } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexXAxis } from 'ng-apexcharts';
export type ChartOptions = {
    chart: ApexChart;
    xaxis: ApexXAxis;
    series: ApexAxisChartSeries;
}
@Pipe({
    name: 'series'
})
export class SeriesPipe implements PipeTransform {

    transform(value: any[]): any {
        const series = value.map(c => {
            return c.value
        });
        return series;
    }

}


@Pipe({
    name: 'labels'
})
export class LabelsPipe implements PipeTransform {

    transform(value: any[]): any {
        const labels = value.map(c => {
            return c.name + ' ' + c.value
        });
        return labels;
    }

}

@Pipe({
    name: 'ChartType'
})
export class ChartTypePipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;

    transform(width: number, type: any): any {
        this.chartOptions = {
            chart: {
                width: width,
                type: type,
            },
        }
        return this.chartOptions.chart;
    }

}

@Pipe({
    name: 'ChartXaxis'
})
export class ChartXaxisPipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;

    transform(value: any[]): any {
        const labels = value.map(c => {
            return c.name + ' ' + c.value
        });
        this.chartOptions = {
            xaxis: {
                categories: [
                    ...labels
                ]
            }

        }
        return this.chartOptions.xaxis;
    }

}


@Pipe({
    name: 'barSeries'
})
export class BarSeriesPipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;

    transform(value: any[]): any {
        const series = value.map(c => {
            return c.value
        });
        this.chartOptions = {
            series: [
                {
                    name: "Total",
                    data: [
                        ...series
                    ]
                }
            ]
        }
        return this.chartOptions.series;
    }

}

