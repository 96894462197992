import { Pipe, PipeTransform } from '@angular/core';
import { toDateKey } from '../services/mapping.service';

@Pipe({
  name: 'findItsAttendanceStat'
})
export class FindItsAttendanceStatPipe implements PipeTransform {

  transform(attendanceStats: Array<any>, dateMonthKey: any) {
    return attendanceStats.find((item) => {
      return item.key === dateMonthKey.field;
    });
  }

}


@Pipe({
  name: 'findRevokedPermissionDay'
})
export class FindRevokedPermissionDayPipe implements PipeTransform {
  transform(permissions: any[], date): any {
    if (permissions && permissions.length > 0) {
      const dateKey = toDateKey(date)
      const doc = permissions.find(m => m?.revokedArrayDateKey?.includes(dateKey))
      return doc ? true : false;
    }
    return false;
  }

}
