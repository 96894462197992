import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCampus'
})
export class FilterCampusPipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    return values.filter(m=>m.campus && m.campus.key===args);
  }

}
