import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateRemarkColorCssClass'
})
export class GenerateRemarkColorCssClassPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value.toLowerCase() === 'ok') return 'present-remark-color';
    else if(value.toLowerCase() === 'l') return 'late-remark-color';
    else if(value.toLowerCase() === 'a') return 'absent-remark-color';
    else if(value.toLowerCase() === 'm') return 'mission-remark-color';
    else if(value.toLowerCase() === 'p') return 'permission-remark-color';
  }

}


@Pipe({
  name: 'remarkColorClassNoBackground'
})
export class RemarkColorClassNoBackgroundPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value.toLowerCase() === 'ok') return 'present-color';
    else if(value.toLowerCase() === 'l') return 'late-color';
    else if(value.toLowerCase() === 'a') return 'absent-color';
    else if(value.toLowerCase() === 'm') return 'mission-color';
    else if(value.toLowerCase() === 'p') return 'permission-color';
  }
}
