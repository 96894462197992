import { Pipe, PipeTransform } from '@angular/core';
import { ConvertService } from '../services/convert.service';

@Pipe({
  name: 'sumReceiptBalance'
})
export class SumReceiptBalancePipe implements PipeTransform {

  transform(item: any, args?: any): any {
    const balance = ((item.balance+item.cash_in+item.installment)-(item.petty_cash))
    return ConvertService.toNumber(balance);
  }

}
