<div class="print-wrapper no-shadow p-0">
  <div class="row-header">
    <div class="title">
      Print Preview
    </div>
    <div class="btn-print">
      <button mat-icon-button (click)="print()" class="print-btn"><i class="material-icons">&#xE8AD;</i></button>

    </div>
    <div class="close-btn-report ml-5" (click)="dialogRef.close('yes')">
      <i class="material-icons">&#xE14C;</i>
    </div>
  </div>

  <div class="row-body">
    <div class="card-wrapper pt-0">
      <div class="report-wrapper">
        <div id="printD" class="print-wrapper atTop">
          <section class="sheet relative">
            <div class="sheet-content">
              <div class="report-header">
                <div class="d-flex m-header">
                  <div class="flex-1 text-center">
                    <div class="logo-block">
                      <img src="../../../assets/images/logo.png" alt="">
                    </div>
                    <div class="com-info text-center">
                      <div class="t1">
                        {{"name" | getSchoolConfig}}
                      </div>
                      <!-- <div class="tl">
                          Sila Samādhi Paññā
                        </div> -->
                      <div class="tl">
                      </div>
                    </div>
                  </div>
                  <div class="flex-1">

                  </div>
                </div>
                <div class="">
                  <div class="recept-title">Receipt Summary</div>
                  <div class="recept-date">On {{data?.create_date?.toDate()|date:'medium'}}</div>
                </div>
                <div class="user-info-report">
                  <h4>Cashier: {{data?.create_by?.displayName}} - {{data?.create_by?.email}}</h4>
                </div>
              </div>
              <div class="report-body">
                <table class="listing-table">
                  <tr>
                    <th class="">Description</th>
                    <th class="al-r">Total</th>
                  </tr>
                  <tr *ngFor="let item of store?.group;let i=index">
                    <td>{{item?.invoice_type?.text}}</td>
                    <td class="al-r">
                      {{(item?.price|sumReport:store?.data:item?.invoice_type?.key:"price")|currency}}
                    </td>
                  </tr>
                  <tr *ngIf="store?.penalty?.length>0">
                    <td>Penalty</td>
                    <td class="al-r">
                      {{(store?.penalty|sumArray:"penalty")|currency}}
                    </td>
                  </tr>
                  <tr>
                    <td>Discount</td>
                    <td class="al-r">
                      {{(store?.dataHeader|sumDiscountArray)*-1|currency}}
                    </td>
                  </tr>
                  <tr>
                    <td>Scholarship</td>
                    <td class="al-r">
                      {{((store?.dataHeader|sumScholarshipArray)*-1)|currency}}
                    </td>
                  </tr>

                  <tr class="dr">
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td class="al-r">
                      <strong>
                        {{store?.balance|currency}}
                      </strong>
                    </td>
                  </tr>

                  <ng-container *ngIf="store?.installmentData?.length>0">
                    <tr>
                      <th class="">Instalment</th>
                      <th class="al-r">Total</th>
                    </tr>
                    <tr *ngFor="let item of store?.installmentData;let i=index">
                      <td>{{item?.student?.full_name}}</td>
                      <td class="al-r">
                        {{item?.price|currency}}
                      </td>
                    </tr>
                    <tr class="dr">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td class="al-r">
                        <strong>{{store?.installmentData|sumArray:"price"|currency}}</strong>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="store?.dataSource?.length>0">
                    <tr>
                      <th class="">Petty Cash</th>
                      <th class="al-r">Total</th>
                    </tr>
                    <tr *ngFor="let item of store?.dataSource;let i=index">
                      <td>{{item?.description}}</td>
                      <td class="al-r">
                        {{item?.price*-1|currency}}
                      </td>
                    </tr>
                    <tr class="dr">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td class="al-r">
                        <strong>{{(store?.dataSource|sumArray:"price")*-1|currency}}</strong>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container>
                    <tr class="dr">
                      <td>
                        <strong>Open balance</strong>
                      </td>
                      <td class="al-r">
                        {{(store?.drawer|sumArray:"cash_in")|currency}}
                      </td>
                    </tr>
                    <tr class="dr">
                      <td>
                        <strong>Grand total</strong>
                      </td>
                      <td class="al-r">
                        <strong>
                          {{
                          ((store?.balance)+
                          (store?.drawer|sumArray:"cash_in")+
                          (store?.installmentData|sumArray:"price")
                          -(store?.dataSource|sumArray:"price"))|currency
                          }}
                        </strong>
                      </td>
                    </tr>
                  </ng-container>
                </table>

              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>