import { Component, ElementRef, Inject, OnInit, ViewChild, ɵɵsetComponentScope } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrintService } from '../../services/print.service';
import * as XLSX from 'xlsx';

import { groupByData, orderBy } from '../../services/mapping.service';
import { Schedule } from 'src/app/core/stores/schedule.store';

@Component({
  selector: 'app-employee-schedule-attendance',
  templateUrl: './employee-schedule-attendance.component.html',
  styleUrls: ['./employee-schedule-attendance.component.scss']
})
export class EmployeeScheduleAttendanceComponent implements OnInit {
  @ViewChild('table') table: ElementRef;
  loading = false;
  att;
  session = [];
  sessionGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmployeeScheduleAttendanceComponent>,
    private ps: PrintService,
    public store: Schedule,


  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const schedule = await this.store.fetchEmloyeeSchedule(this.data.academicYear.key, this.data.item.employee.key)
    for (let index = 0; index < schedule.length; index++) {
      const scheduleDoc = schedule[index];
      const { sessionItems } = scheduleDoc;
      const data = sessionItems.map(f => {
        return {
          session: f,
          scheduleKey: scheduleDoc.key,
          batch: scheduleDoc.batch,
          instructor: scheduleDoc.instructor,
          schedule_subject: scheduleDoc.schedule_subject
        }
      })
      this.session.push(...data)
    }
    this.sessionGroup = orderBy(groupByData(this.session, 'session', 'session.fromHoursNumber'), 'session.fromHoursNumber')
    this.loading = false;

  }

  async exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'SheetJS.xlsx');
  }

  onClose() {
    this.dialogRef.close()
  }

  onPrint() {
    this.ps.print('#PRINT_TABLE', 'a4l')
  }
}
