import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-question-data-text',
  templateUrl: './question-data-text.component.html',
  styleUrls: ['./question-data-text.component.scss']
})
export class QuestionDataTextComponent implements OnInit {
  @Input() textArray: any[];
  @Input() canEdit: boolean;

  @Output() onText = new EventEmitter()
  @Output() onLaText = new EventEmitter()
  @Output() onBreak = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  _onText(text) {
    if (!this.canEdit) return;
    this.onText.emit(text)
  }

  _onLaText(text) {
    if (!this.canEdit) return;
    this.onLaText.emit(text)
  }

  _onBreak(text) {
    if (!this.canEdit) return;
    this.onBreak.emit(text)
  }
}
