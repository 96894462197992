<div class="mat-dialog-full-box dark" *mobxAutorun>
  <div class="nav-profile">

    <div class="profile-header">
      <div class="header-actions">
        <button mat-button mat-icon-button (click)="_goBack()">
          <mat-icon aria-label="Example icon-button with a heart icon">arrow_back</mat-icon>
        </button>
      </div>
      <h4>{{store?.SelectedconfirmPayments?.displayName}}</h4>
      <div class="header-actions">
        <!-- <div class="campus-select">
          <button>
            <i class="material-icons">schedule</i>
            <div class="name">{{ env?.term?.code }}</div>
          </button>
        </div> -->
      </div>
    </div>

    <div class="profile-header col">
      <h3 *ngIf="store?.SelectedconfirmPayments">Closing Shift Payment - {{ store?.SelectedconfirmPayments?.campus?.name }}</h3>
      <div class="group-buttons" *ngIf="store?.SelectedconfirmPayments">
        <div class="group-mat-button">
          <button mat-button mat-fab class="active" >
            <mat-icon aria-label="Transcript">ballot</mat-icon>
          </button>
          <span class="text-group">All</span>
        </div>
  
      </div>
    </div>

  </div>

  <div class="apps-contain flat-full">
    <router-outlet></router-outlet>
  </div>
</div>