<div class="mat-dialog-full-box dark" *mobxAutorun>
  <div class="nav-profile">
    <div class="profile-header">
      <div class="header-actions">
        <button mat-button mat-icon-button (click)="_goBack()">
          <mat-icon aria-label="Example icon-button with a heart icon">arrow_back</mat-icon>
        </button>
      </div>
      <h4>
        {{ store?.dataDoc?.name }}
      </h4>

      <div class="header-actions">
        <!-- <div class="campus-select">
           <button>
              <i class="material-icons">schedule</i>
              <div class="name">
                {{env?.academic?.term?.code}}
              </div>
           </button>
         </div> -->
      </div>
    </div>
    <div class="profile-header col">
      <h3 *ngIf="store?.dataDoc">Receipt Listing Report - {{ store?.dataDoc?.campus?.name }}</h3>
      <div class="group-buttons" *ngIf="store?.dataDoc">
        <div class="group-mat-button">
          <button mat-button mat-fab routerLinkActive="active" [routerLink]="['/payment-listing/' + store?.dataDoc?.key + '/all' ]">
            <mat-icon aria-label="Transcript">ballot</mat-icon>
          </button>
          <span class="text-group">All</span>
        </div>
        <!-- <div class="group-mat-button">
          <button mat-button mat-fab routerLinkActive="active" [routerLink]="['/payment-listing/' + store?.dataDoc?.key +'/tuition_fee/program']">
            <mat-icon aria-label="Transcript">how_to_reg</mat-icon>
          </button>
          <span class="text-group">Tuition Fee</span>
        </div>
        <div class="group-mat-button">
          <button mat-button mat-fab routerLinkActive="active"
            [routerLink]="['/payment-listing/' + store?.dataDoc?.key + '/academic_program/program']">
            <mat-icon aria-label="Transcript">school</mat-icon>
          </button>
          <span class="text-group">Academic</span>
        </div>
        <div class="group-mat-button">
          <button mat-button mat-fab routerLinkActive="active"
            [routerLink]="['/payment-listing/' + store?.dataDoc?.key +'/institute_and_center/program']">
            <mat-icon aria-label="Transcript">local_library</mat-icon>
          </button>
          <span class="text-group">Institute</span>
        </div>
        <div class="group-mat-button" *ngFor="let item of cstab">
          <button mat-button mat-fab routerLinkActive="active" [routerLink]="[item?.path]">
            <mat-icon aria-label="Transcript">{{item.icon}}</mat-icon>
          </button>
          <span class="text-group">{{item?.label}}</span>
        </div> -->
      </div>
    </div>
  </div>

  <div class="apps-contain flat-full">
    <router-outlet></router-outlet>
    <!-- <app-spinner *ngIf="store?.loading"></app-spinner> -->
  </div>
</div>