import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEmployeeHour'
})
export class FilterEmployeeHourPipe implements PipeTransform {

  transform(value: any[], dateKey: any): unknown {
    if (value && dateKey) {
      const emp_hour = value.find(m => m.dateKey === dateKey)
      const att_length = emp_hour.att.filter(m => !m.remark || m.remark === null || m.remark.name === 'OK' || m.remark.name === 'L')
      return att_length.length
    }
    return null;
  }
}

@Pipe({
  name: 'filterEmployeeSession'
})
export class FilterEmployeeSessionPipe implements PipeTransform {
  transform(session: any, attendance_confirmation_logs: any[], date: any): unknown {
    if (attendance_confirmation_logs.length > 0) {
      const emp_hour = attendance_confirmation_logs.find(m => m.session.fromHoursNumber === session.fromHoursNumber && m.date_key === date.dateKey)
      if (emp_hour && emp_hour.date_key) {
        console.log('emp_hour')
      }

      if (emp_hour) {
        const adminConfirm = emp_hour.create_by.key === emp_hour.instructor.key ? false : true;
        const data = {
          remark: emp_hour?.remark?.name || 'OK',
          note: emp_hour.note,
          adminConfirm: adminConfirm,
          confirmBy: adminConfirm && emp_hour?.remark?.name === 'M' ? '(Admin Approve)' : '(Admin Confirm)'
        }
        return data
      }
      return {
        remark: 'Not Confirm',
        adminConfirm: false
      };
    }
    return {
      remark: 'Not Confirm',
      adminConfirm: false
    };
  }

}

@Pipe({
  name: 'filterEmployeeSchedule'
})
export class FilterEmployeeSchedulePipe implements PipeTransform {
  transform(groupSession: any, session: any[], date: any): unknown {
    if (groupSession && session.length > 0) {
      const sessionSchedule = session.find(m => m.session.fromHoursNumber === groupSession.fromHoursNumber && m.session.sessionDay[0].key === date.dayKey)
      return sessionSchedule
    }
    return null;
  }

}

@Pipe({
  name: 'employeeScheduleAttRemark'
})
export class EmployeeScheduleAttRemarkPipe implements PipeTransform {
  transform(confirmAtt: any): unknown {
    let data;
    if (confirmAtt) {
      if (confirmAtt.create_by === confirmAtt.instructor.key || confirmAtt.create_by.key === confirmAtt.instructor.key) {
        data = {
          remark: confirmAtt?.remark?.name || 'OK',
          note: confirmAtt.note,
          adminConfirm: false
        }
      } else {
        data = {
          remark: confirmAtt?.remark?.name || 'OK',
          note: confirmAtt.note,
          adminConfirm: true
        }
      }
      return data;
    }
    return {
      remark: 'Not Confirm',
      note: 'Not Confirm',
      adminConfirm: false
    };
  }

}




@Pipe({
  name: 'filterScheduleHoliday'
})
export class FilterScheduleHolidayPipe implements PipeTransform {
  transform(holiday: any[], date: any): unknown {
    if (holiday && holiday.length > 0) {
      const isHoliday = holiday.find(m => m.holidayKey === date)
      return isHoliday ? isHoliday : null
    }
    return null;
  }

}


