import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'convertDateKeyToDate'
})
export class ConvertDateKeyToDatePipe implements PipeTransform {

  transform(date: number): unknown {
    return moment(date, "YYYYMMDD").toDate();
  }

}
