<div class="mat-dialog-full-box" *mobxAutorun>
  <div class="nav-profile"
    style="min-height: 227px">
    <ng-container *ngIf="!store?.loading">
      <div class="profile-header">
        <div class="header-actions">
          <button mat-button mat-icon-button (click)="_goBack()">
            <mat-icon aria-label="Example icon-button with a heart icon">arrow_back</mat-icon>
          </button>
        </div>
        <h4>
          {{ store?.student?.puc_id }}<span *ngIf="store?.student">,
            {{ store?.student?.full_name }},
            {{store?.student?.campus?.name}}</span>
        </h4>
        <div class="header-actions">
          <div class="campus-select">
            <button [matMenuTriggerFor]="menu">
              <i class="material-icons">event</i>
              <div class="name" *ngIf="env?.selectedTerm">
                {{env?.selectedTerm?.code}}
              </div>
              <i class="material-icons">arrow_drop_down</i>
            </button>
            <mat-menu #menu="matMenu" class="mat-campus">
              <button (click)="onSelectedTerm(term)" class="csmat-menu-item camp"
                [ngClass]="term.key===env?.selectedTerm.key?'active':''" mat-menu-item *ngFor="let term of termList">
                <div class="mat-menu-item-campus">
                  <mat-icon>event</mat-icon>
                  <div class="mat-info-select">
                    <strong>{{term?.code}}</strong>
                    <div class="subtitle">{{term?.name}}, {{term?.startterm?.toDate()|dateFormat}} to
                      {{term?.endterm?.toDate()|dateFormat}}</div>
                  </div>
                </div>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="profile-header col">
        <h3 *ngIf="store?.selectedAdmission && store?.selectedAdmission?.admission_type.key === 3">
          {{ store?.selectedAdmission?.faculty?.name }}
          <span *ngIf="store?.selectedAdmission">, </span> {{ store?.selectedAdmission?.major?.name }}
        </h3>
        <h3 *ngIf="store?.student?.mobile_phone && !process">
          Tel: {{ store?.student?.mobile_phone }} <span *ngIf="store?.student?.email">,Email:
            {{ store?.student?.email }}</span>
        </h3>
        <h3 *ngIf="!store?.student?.mobile_phone && !process">
          Tel: Unknown
        </h3>

        <!-- <h3 *ngIf="store?.selectedAdmission && store?.selectedAdmission?.admission_type.key === 2">
          &nbsp;{{store?.selectedAdmission?.program_academic?.name}}
          - {{store?.selectedAdmission?.program_academic?.category?.name}}&nbsp;</h3>
        <h3 *ngIf="!store?.selectedAdmission">No Admission</h3> -->
        <h3 *ngIf="!store?.selectedAdmission">No Admission</h3>
        <h3 *ngIf="store?.selectedAdmission">&nbsp;{{store?.selectedAdmission?.program_academic?.name}}
          - {{store?.selectedAdmission?.program_academic?.category?.name}}&nbsp;</h3>
        <div class="group-buttons" *ngIf="store?.admissions">

          <div class="group-mat-button">
            <button mat-fab routerLinkActive="active" [routerLink]="['/student/' + store?.student?.key + '/payment']">
              <mat-icon aria-label="Transcript">monetization_on</mat-icon>
            </button>
            <span class="text-group">Payments</span>
          </div>

          <div class="group-mat-button">
            <button mat-fab routerLinkActive="active"
              [routerLink]="['/student/' + store?.student?.key + '/clear-unpaid']">
              <mat-icon aria-label="Transcript">account_balance_wallet</mat-icon>
            </button>
            <span class="text-group">Clear Unpaid</span>
          </div>

          <!-- <div class="group-mat-button">
            <button mat-fab routerLinkActive="active" [routerLink]="['/student/' + store?.student?.key + '/unpaid']">
              <mat-icon aria-label="Transcript">payment</mat-icon>
            </button>
            <span class="text-group">Unpaid</span>
          </div> -->

          <div class="group-mat-button">
            <button mat-fab routerLinkActive="active"
              [routerLink]="['/student/' + store?.student?.key + '/scholarship']">
              <mat-icon aria-label="Transcript">card_giftcard</mat-icon>
            </button>
            <span class="text-group">Scholarship</span>
          </div>

          <!-- <div class="group-mat-button">
            <button mat-fab routerLinkActive="active"
              [routerLink]="['/student/' + store?.student?.key + '/scholarship-penalty']">
              <mat-icon aria-label="Transcript">warning</mat-icon>
            </button>
            <span class="text-group">Penalty</span>
          </div> -->

          <!-- <div class="group-mat-button">
            <button mat-fab routerLinkActive="active"
              [routerLink]="['/student/' + store?.student?.key + '/enrollment']">
              <mat-icon aria-label="Transcript">playlist_add</mat-icon>
            </button>
            <span class="text-group">Enrollment</span>
          </div> -->

          <!-- <div class="group-mat-button">
            <button mat-fab routerLinkActive="active" [routerLink]="['/student/' + store?.student?.key + '/profile']">
              <mat-icon aria-label="Transcript">account_circle</mat-icon>
            </button>
            <span class="text-group">Profile</span>
          </div> -->

          <!-- <div class="group-mat-button">
            <button mat-fab routerLinkActive="active" [routerLink]="['/student/' + store?.student?.key + '/history']">
              <mat-icon aria-label="Transcript">history</mat-icon>
            </button>
            <span class="text-group">History</span>
          </div>

          <div class="group-mat-button">
            <button mat-fab routerLinkActive="active" [routerLink]="['/student/' + store?.student?.key + '/schedule']">
              <mat-icon aria-label="Transcript">history</mat-icon>
            </button>
            <span class="text-group">Schedule</span>
          </div>

          <div class="group-mat-button" *ngFor="let item of store?.admissions; let i = index">
            <button *ngIf="i === 0" mat-fab routerLinkActive="active"
              [routerLink]="['/student/' + store?.student?.key + '/preview']">
              <mat-icon aria-label="enrollment">{{icons[i]}}</mat-icon>
            </button>
            <button *ngIf="i > 0" mat-fab routerLinkActive="active" [routerLink]="[
                  '/student/' + store?.student?.key + '/program/' + item?.key
                ]">
              <mat-icon aria-label="enrollment">{{icons[i]}}</mat-icon>
            </button>
            <span class="text-group">{{ item?.program?.name }}</span>
          </div> -->
        </div>
      </div>
    </ng-container>

  </div>
  <div class="apps-contain" style="max-width: unset;">
    <!-- <div class="search-engine profile-contain">
      <form [formGroup]="form" class="search-nav" [ngClass]="{'active':formFocus}" (ngSubmit)="_onSearch(form.value)">
        <div class="enroll-filter">
          <mat-select (selectionChange)="_selectionChange($event)" formControlName="searchType"
            [compareWith]="compareObjects">
            <mat-option *ngFor="let item of filterBy;" [value]="item">
              {{ item.text }}
            </mat-option>
          </mat-select>
        </div>
        <input type="text" formControlName="search" [matAutocomplete]="auto" (focus)="_onFocus(true)"
          (blur)="_onFocus(false)" appFocusSearch placeholder="Search Student">
        <mat-autocomplete [displayWith]="displayItem" #auto="matAutocomplete" class="mat-result-items">
          <div class="mat-auto-header">
            <strong>Students</strong>
            <span>{{env?.statistic?.totalStudent|number:'3.0-2'}} Students</span>
          </div>
          <mat-option (click)="_optionSelected(state)" *ngFor="let state of searchStore?.data" [value]="state">
            <svg viewBox="0 0 24 24" role="presentation" aria-hidden="true" focusable="false"
              style="height: 32px; width: 32px; display: block; fill: rgb(255, 180, 0);">
              <path
                d="m17.61 13.4-2.49-.68c-2.76-.75-.51-2.87-2.88-3.51l-2.88-.78a.9.9 0 0 0 -1.1.62l-1.27 4.54 3.81 1.04c2.37.64.13 2.76 2.88 3.51l2.72.74 3.26.89z">
              </path>
              <path
                d="m1.5 22a .52.52 0 0 1 -.13-.02.5.5 0 0 1 -.35-.61l5.21-19.02a.5.5 0 0 1 .61-.35l6.92 1.88a2.02 2.02 0 0 1 1.65 2.06c.08.69.13 1.15 1.23 1.45l5.98 1.63a.5.5 0 0 1 .2.86l-4.55 4.04 1.86 5.78a.5.5 0 0 1 -.61.64l-5.98-1.63a2.29 2.29 0 0 1 -1.96-2.3c-.07-.66-.11-.99-.92-1.21l-3.82-1.04a.5.5 0 1 1 .25-.97h.01l3.82 1.04a2.03 2.03 0 0 1 1.65 2.07c.08.69.13 1.15 1.23 1.45l5.1 1.39-1.67-5.22a.5.5 0 0 1 .14-.53l4.11-3.64-5.09-1.39a2.29 2.29 0 0 1 -1.96-2.3c-.07-.66-.11-.99-.92-1.21l-6.44-1.75-5.08 18.54a.5.5 0 0 1 -.48.37z"
                fill="#484848"></path>
            </svg>
            <span class="flex1 mat-space">{{state?.full_name}}</span>
            <small>{{state?.puc_id}}</small>
          </mat-option>
          <mat-option disabled class="mat-empty-result" *ngIf="!loading && searchStore?.data?.length===0">
            <img
              src="https://mobilecenter.azureedge.net/generated/img/no-filtered-apps-5e1a9c8b30813a99f20a80479de65977.svg">
            <h3>No data found with current keyword.</h3>
          </mat-option>
        </mat-autocomplete>
        <button type="button" mat-icon-button (click)="_onSearch(form.value)">
          <mat-icon aria-label="search">search</mat-icon>
        </button>
      </form>
    </div> -->
    <router-outlet></router-outlet>
    <!-- <app-spinner *ngIf="store?.loading || env?.loading"></app-spinner> -->
  </div>
</div>