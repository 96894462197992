<div class="empty-block">
    <div class="center-block-empty">
      <div class="empty-img">
        <img src="https://mobilecenter.azureedge.net/generated/img/no-apps-db6363dd644196e2291b243bc210e1b0.svg" alt="">
      </div>
      <div class="empty-text">
        <p class="empty-state-title">{{header}}</p>
        <p class="empty-state-desc">{{text}}</p>
      </div>
    </div>
  </div>