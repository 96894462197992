import { Router } from '@angular/router';
import { observable, action, computed, toJS, } from 'mobx';
import { Injectable, NgZone } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import 'firebase/firestore';
import { auth, } from 'firebase/app';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { UserStore } from './user.store';
import { SCHOOL } from '../dummy/config';
import { MappingService, orderBy, pushToArray, pushToObject } from 'src/app/shared/services/mapping.service';
import * as firebase from 'firebase/app';

@Injectable({ providedIn: 'root' })
export class AuthStore {
  @observable remember: boolean = false;
  @observable isLogged: boolean = false;
  @observable process: boolean = false;
  @observable loading: boolean = true;
  @observable error: any;

  @observable user: any = null;
  @observable hqAccount: boolean = false;
  @observable uid: any = null;
  @observable apps: any[] = [];
  @observable appName: any = SCHOOL.name;
  @observable storeKey: string = "ewis_school";
  @observable selectedCampus: any = null;
  @observable selectedSchool: any = null;
  @observable campus: any[] = [];

  @observable userRole: any = null;

  @observable memberOf: any = null;
  @observable selectedTerm: any = null;
  @observable selectedYear: any = null;
  @observable terms: any[] = [];


  constructor(
    private router: Router,
    private auth: AuthService,
    private ds: DataService,
    private ngZone: NgZone,
    private userStore: UserStore
  ) {
    this.fetchCanActive();
  }
  @action
  changePassword(oldPassword: any, newPassword: any, callback) {
    const user = firebase.auth().currentUser;;
    this.auth.authRef().signInWithEmailAndPassword(user.email, oldPassword).then((account) => {
      user.updatePassword(newPassword).then(() => {
        callback(true, null)
      }).catch(error => {
        alert(error)
        callback(false, error)
      })
    }).catch(error => {
      alert(error)
      callback(false, error)
    })
  }
  @action
  async fetchActiveTerm(callback?: (term: any) => any) {
    const doc = await this.ds.academicRef().get().toPromise();
    if (doc.exists) {
      const { term, year } = pushToObject(doc);
      this.selectedTerm = term;
      this.selectedYear = year;
      return callback && callback(term);
    }
  }

  @observable fetchTermsRef: any = null;
  @action
  fetchTerms(callback?) {
    this.process = true;
    this.fetchTermsRef = this.ds.termAcademicReportRef()
      .valueChanges()
      .subscribe(docs => {
        this.terms = docs;
        this.process = false;
        if (callback) callback(this.terms)
      })
  }

  @observable fetchInstituteTermRef: any = null;
  @action
  fetchInstituteTerm(instituteKey: string, callback?) {
    this.process = true;
    this.fetchInstituteTermRef = this.ds.instituteTermDocsRef(instituteKey)
      .valueChanges().subscribe(docs => {
        this.terms = docs;
        this.process = false;
        if (callback) callback(this.terms)
      })
  }

  @action
  async fetchUser() {
    this.uid = (await this.auth.currentUserRef()).uid;
    const doc = await this.ds.userRef(this.uid).get().toPromise();
    const item = pushToObject(doc);
    const { memberOf, appsObject } = item;
    const school = pushToObject(await this.ds.schoolDocRef().get().toPromise())
    this.selectedSchool = school;
    this.user = item;
    this.selectedCampus = item && item.campus;
    this.memberOf = memberOf;
    this.hqAccount = memberOf && memberOf.key === 0;
    this.apps = appsObject;
    return item;
  }

  @action
  async fetchEmployee() {
    const uid = (await this.auth.currentUserRef()).uid;
    const doc = await this.ds.employeeDBRef().doc(uid).get().toPromise();
    const item = pushToObject(doc);
    this.selectedCampus = item && item.campus;
    return item;
  }

  @observable fetchCanActiveRef: any = null;
  @action
  fetchCanActive() {
    this.error = null;
    this.loading = true;
    this.auth.canActiveRef().subscribe(async user => {
      this.loading = true;
      if (user) {
        this.fetchCanActiveRef = this.ds.userRef(user.uid)
          .valueChanges()
          .subscribe(async (doc: any) => {
            this.user = doc;

            this.uid = doc.key;
            this.selectedCampus = doc.campus;
            const school = pushToObject(await this.ds.schoolDocRef().get().toPromise())
            this.selectedSchool = school;
            let campus = [];
            if(this.selectedSchool){
              campus = pushToArray(await this.ds.campusStoreRef(this.selectedSchool?.key).get().toPromise())
              campus.push({
                key: 'all',
                name: 'All', text: 'All',
                khName: 'All', order: "000", status: { key: 1, text: 'Active' }
              })


            }
            this.campus = campus?.filter(m => m?.status.key === 1)



            this.campus = this.user.isAdmin || this.user.isAllCampus ? this.campus : this.campus.filter(m => m.key === doc.campus.key)
            this.campus = orderBy(orderBy(this.campus, "order"), "name")
            this.userRole = doc.memberOf;
            localStorage.setItem('user', JSON.stringify(this.user));
            JSON.parse(localStorage.getItem('user'));
            this.loading = false;
          })
      } else {
        this.signOut();
      }
    })
  }

  @action
  async fetchUserDoc(callback) {
    const userData: any = JSON.parse(localStorage.getItem('user'));
    const key = userData.key;
    const doc: any = await this.ds.userDocRef(key).get().toPromise();
    this.user = MappingService.pushToObject(doc);
    const school = pushToObject(await this.ds.schoolDocRef().get().toPromise())
    this.selectedSchool = school;
    this.selectedCampus = doc.campus;
    callback(this.user)
  }

  @action
  signIn(email, password) {
    this.process = true;
    this.error = null;
    return this.auth.authRef().signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          const { user } = result;
          if (user) {
            this.ds.userRef(user.uid).valueChanges().subscribe(doc => {
              if (!doc) {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
                this.error = "Invalid your email and password or access denied.";
                this.router.navigate(['/auth']);
                this.process = false;
                return;
              }

              if (doc?.isAdmin === true || (doc?.school?.key === SCHOOL.key && (doc?.groupAccountKey && doc?.groupAccountKey?.length > 0))) {
                this.user = doc;
                this.uid = doc.key;

                localStorage.setItem('user', JSON.stringify(this.user));
                JSON.parse(localStorage.getItem('user'));
                this.router.navigate(['/']);
                this.process = false;
              } else {

                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
                this.error = "Invalid your email and password or access denied.";
                this.router.navigate(['/auth']);
                this.process = false;
                return;
              }

              // const { apps } = doc;
              // if (!apps || apps.length === 0) {
              //   localStorage.setItem('user', null);
              //   JSON.parse(localStorage.getItem('user'));
              //   this.error = "Invalid your email and password or access denied.";
              //   this.signOut();
              //   this.process = false;
              // } else {

              //   this.user = doc;
              //   this.uid = doc.key;
              //   localStorage.setItem('user', JSON.stringify(this.user));
              //   JSON.parse(localStorage.getItem('user'));

              //   this.router.navigate(['/']);
              //   this.process = false;
              // }
            })
          }
        });
      }).catch((error) => {
        // window.alert(error.message)
        this.error = "Invalid your email and password or access denied.";
        this.process = false;
      })
  }

  // Sign up with email/password
  @action
  signUp(email, password) {
    return this.auth.authRef().createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
        // this.sendVerificationMail();

      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  @action
  sendVerificationMail() {
    return this.user.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  // Reset Forggot password
  @action
  forgotPassword(passwordResetEmail) {
    return this.auth.authRef().sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  @computed
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null ? true : false;
  }

  // Sign in with Google
  @action
  googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider()).then(() => {
      this.router.navigate(['dashboard']);
    });
  }

  @action
  resetPassword(email) {
    return this.auth.authRef().sendPasswordResetEmail(email);
  }

  // Auth logic to run auth providers
  @action
  authLogin(provider) {
    return this.auth.authRef().signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  @action
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.auth.userRef().doc(user.uid);
    const userData: any = {
      key: user.uid,
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out
  @action
  signOut() {
    this.error = null;
    this.fetchCanActiveRef && this.fetchCanActiveRef.unsubscribe();
    this.auth.authRef().signOut().then(() => {
      this.user = null;
      this.uid = null;
      this.userStore.clearStore();
      this.router.navigate(["/auth/login"]);
      localStorage.removeItem('user');
    })
  }

}
