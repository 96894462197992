
export let CONFIG = {
    PROGRAM: '5rpL8docrvGBImXXmYEy',
    UNIVERSITY_LEVEL: '0t8YU0G6upcarWr3JQPR',
    DEGREE: 'LWdEa5r6A8bv8WWz5XPy',
    FEE_PROGRAM: 'H5MkjZpy2BhsaasOOaC1',
    GPT: '3FvaZmN22NZON1cv3J0Z',
    PUC_IFL_KEY: 'DBrSzLY3UQ2kEgF50nfU',
    FOLDER_NAME: 'TERM20200403'
}

export const TEXT_TYPES: any = {
    TEXT: { key: 1, name: 'អក្សរសំណេរ' },
    MATH: { key: 2, name: 'អក្សរគណិត' }
};

export const APPS = {
    SIZE: 30,
    DEBOUNCE_TIME: 300,
    PLACEMENT_TEST_DURATION: 3600,
}
export const COLLECTION_NAME = {
    PROGRAM: 'resource_programs',
    LEVEL: 'resource_levels',
    LESSON: 'resource_lessons',
    GRAMMAR_IN_USE: 'grammar_in_use',
    MODULE: 'resource_modules',
    ACTIVITY: 'resource_activities',
    ACTIVITY_TYPE: 'resource_activity_types',
    SKILL: 'resource_skills',
    DIFFICULTY: 'resource_difficulty',
    BOOK_STORE_TRENDING: 'book_store_trending',
    BOOK_STORE_GENRES: 'book_store_genres',
    BOOK_STORE_SECTIONS: 'book_store_sections',
    BOOK_STORE_BOOKS: 'book_store_books',
    BOOK_STORE_AUTHOR: 'book_store_authors',
    GENERAL_PLACEMENT_TEST: 'general_placement_test',
    BOOK_STORE_PUBLISHER: 'book_store_publishers',
    BOOK_STORE_SLIDES: 'book_store_slides',
    VOCABULARY: 'vocabulary',
    GRAMMAR: 'grammar',
    LISTENING: 'listening',
    READING: 'reading',
    GENERAL_TESTING: 'general_testing',
    TUTOR: 'tutors',
    CLASSROOMS: 'classrooms',
    STUDENTS: 'students',

    //X-READING
    READING_LIBRARY: 'reading_library',
    READING_LEVEL: 'reading_level',
    READING_WORDS: 'reading_words',
    READING_ENGLISH_TYPE: 'reading_english_types',
    READING_FORMAT: 'reading_format',
    READING_TARGET_AGE: 'reading_target_age',
    READING_TYPE: 'reading_type',
}


export const PRICE_OPTIONS = [
    { key: 1, name: 'Sell' },
    { key: 2, name: 'Free' },
]

export const LANGUAGE_LIST = [
    { key: 2, name: 'Khmer', code: 'KH' },
    { key: 1, name: 'English', code: 'EN' },
]

export const modulesEditor = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],

        ['link']
    ]
};

export const EXAM_OBJ = {
    MCQ: { key: 1, kh: 'វិញ្ញាសាជ្រើសចម្លើយត្រឹមត្រូវតែមួយគត់', name: 'Multiple Choice Question', icon: 'dialpad', router: 'mcq' },
    TRUE_FALSE: { key: 2, kh: 'វិញ្ញាសាសរសេរត្រូវឬខុស', name: 'True/False Question', icon: 'wrap_text', router: 'true-and-false-question' },
    MATCHING: { key: 3, kh: 'វិញ្ញាសាបែបផ្គូផ្គង', name: 'Matching Exercise', icon: 'transform', router: 'matching-exercises' },
    SENTENCE: { key: 4, kh: 'វិញ្ញាសាបែបបំពេញល្បះ', name: 'Sentence Complete', icon: 'spellcheck', router: 'sentence-complete' },
    PICTURE_MATCHING: { key: 5, kh: 'លំហាត់រូបភាពបែបផ្គូផ្គង', name: 'Picture Matching', icon: 'image', router: 'picture-matching' },
    PICTURE_DESCRIPTION: { key: 6, kh: 'វិញ្ញាសារូបភាពជ្រើសរើសចម្លើយ', name: 'Picture Multiple Choice Question', icon: 'camera_alt', router: 'picture-description' },
    EXPLAIN: { key: 7, kh: 'វិញ្ញាសាបែបពន្យល់ពាក្យ', name: 'Explain', icon: 'info', router: 'explain' },
    WRITING: { key: 8, kh: 'វិញ្ញាសាសំណួរត្រិះរិះ', name: 'Writing', icon: 'perm_identity', router: 'writing' },
    ESSAY: { key: 9, kh: 'វិញ្ញាសាតែងសេចក្តី', name: 'Essay', icon: 'subject', router: 'essay' },
    EXERCISES: { key: 10, kh: 'វិញ្ញាសាលំហាត់ដំណោះស្រាយ', name: 'Exercise', icon: 'description', router: 'exercises' },
}

export const GEP_TESTING_TYPES = {
    MCQ: { key: 1, name: 'MCQ' },
    READING: { key: 2, name: 'Reading' },
    WRITING: { key: 3, name: 'Writing' },
}

export const STRING_TYPE_OBJ = {
    TEXT: { key: 1, text: 'Text' },
    MATH_TYPE: { key: 2, text: 'MathType' },
    FILL_TEXT: { key: 3, text: 'Fill Text' },
    FILL_MATH: { key: 4, text: 'Fill MathType' },
    TAB: { key: 5, text: 'Tab' },
    ENTER: { key: 10, text: 'Enter' }
}

export const RECEIVED_STATUS = {
    pending: { key: 1, text: 'Pending', color: '#fead01' },
    error: { key: 2, text: 'Error', color: '#e64f3e' },
    success: { key: 3, text: 'Success', color: '#00c853' },
}


export const GRADE_SHEET_TYPES = {
    'monthly-grade': { key: 1, name: 'ពិន្ទុប្រចាំខែ', enName: 'Monthly Grade', route: 'monthly-grade' },
    'semester-i': { key: 2, name: 'ពិន្ទុឆមាសទី I', enName: 'Semester I', route: 'semester-i' },
    'month-semester-i': { key: 2, name: 'ពិន្ទុប្រចាំខែឆមាសទី 1', enName: 'Final Semester I', route: 'month-semester-i' },
    'semester-ii': { key: 3, name: 'ពិន្ទុឆមាសទី II', enName: 'Semester II', route: 'semester-ii' },
    'month-semester-ii': { key: 3, name: 'ពិន្ទុប្រចាំខែឆមាសទី 2', enName: 'Final Semester II', route: 'month-semester-ii' },
    'yearly-grade': { key: 4, name: 'ពិន្ទុប្រចាំឆ្នាំ', enName: 'Year', route: 'yearly-grade' },
}


export const GRADE_SHEET_TYPES_ARRAY = [
    { key: 1, name: 'ពិន្ទុប្រចាំខែ', enName: 'Monthly Grade', route: 'monthly-grade' },
    { key: 2, name: 'ពិន្ទុឆមាសទី I', enName: 'Semester I', route: 'semester-i' },
    { key: 2, name: 'ពិន្ទុប្រចាំខែឆមាសទី 1', enName: 'Final Semester I', route: 'month-semester-i' },
    { key: 3, name: 'ពិន្ទុឆមាសទី II', enName: 'Semester II', route: 'semester-ii' },
    { key: 3, name: 'ពិន្ទុប្រចាំខែឆមាសទី 2', enName: 'Final Semester II', route: 'month-semester-ii' },
    { key: 4, name: 'ពិន្ទុប្រចាំឆ្នាំ', enName: 'Year', route: 'yearly-grade' },
]

export const MONTH_DATA = [
    { key: 1, kh: 'មករា', name: 'January', id: '01', shortName: 'Jan' },
    { key: 2, kh: 'កុម្ភះ', name: 'February', id: '02', shortName: 'Feb' },
    { key: 3, kh: 'មិនា', name: 'March', id: '03', shortName: 'Mar' },
    { key: 4, kh: 'មេសា', name: 'April', id: '04', shortName: 'Apr' },
    { key: 5, kh: 'ឧសភា', name: 'May', id: '05', shortName: 'May' },
    { key: 6, kh: 'មិថុនា', name: 'June', id: '06', shortName: 'Jun' },
    { key: 7, kh: 'កក្កដា', name: 'July', id: '07', shortName: 'Jul' },
    { key: 8, kh: 'សីហា', name: 'August', id: '08', shortName: 'Aug' },
    { key: 9, kh: 'កញ្ញា', name: 'September', id: '09', shortName: 'Sep' },
    { key: 10, kh: 'តុលា', name: 'October', id: '10', shortName: 'Oct' },
    { key: 11, kh: 'វិច្ឆកា', name: 'November', id: '11', shortName: 'Nov' },
    { key: 12, kh: 'ធ្នូ', name: 'December', id: '12', shortName: 'Dec' },
]

export const month_template_type = [
    { key: 1, kh: 'A', name: 'A'},
    { key: 2, kh: 'B', name: 'B' },
    { key: 3, kh: 'C', name: 'C' },
    { key: 4, kh: 'សៀវភៅតាមដាន', name: 'Track book' },
]

export const semester_template_type = [
    { key: 1, kh: 'A', name: 'A'},
    { key: 2, kh: 'B', name: 'B' },
    { key: 4, kh: 'សៀវភៅតាមដាន', name: 'Track book' },
]

export const year_template_type = [
    { key: 1, kh: 'A', name: 'A'},
    { key: 2, kh: 'B', name: 'B' },
    // { key: 3, kh: 'សៀវភៅតាមដាន', name: 'Track book' },
]

export const ATTENDANCE_TYPE = [
  { key: 1, text: "Check In" },
  { key: 2, text: "Check Out" },
];

export const MONTH_OBJECT = {
    january: { key: 1, kh: 'មករា', name: 'January' },
    february: { key: 2, kh: 'កុម្ភះ', name: 'February' },
    march: { key: 3, kh: 'មិនា', name: 'March' },
    april: { key: 4, kh: 'មេសា', name: 'April' },
    may: { key: 5, kh: 'ឧសភា', name: 'May' },
    june: { key: 6, kh: 'មិថុនា', name: 'June' },
    july: { key: 7, kh: 'កក្កដា', name: 'July' },
    august: { key: 8, kh: 'សីហា', name: 'August' },
    september: { key: 9, kh: 'កញ្ញា', name: 'September' },
    october: { key: 10, kh: 'តុលា', name: 'October' },
    november: { key: 11, kh: 'វិច្ឆកា', name: 'November' },
    december: { key: 12, kh: 'ធ្នូ', name: 'December' },
}


export const GRADE_SHEET_TYPES_FORM = [
    { key: 1, name: 'ពិន្ទុប្រចាំខែ', enName: 'Monthly Grade' },
    { key: 2, name: 'ពិន្ទុឆមាសទី I', enName: 'Semester I' },
    { key: 3, name: 'ពិន្ទុឆមាសទី II', enName: 'Semester II' },
]

