<div *ngIf="isCameraExist; else noCameraExist">
  <div class="buttons-camera-container">
    <div class="btn-group">
      <button class="button" (click)="takeSnapshot()">Take Picture</button>
      <button class="button" (click)="changeWebCame(true)">Change Camera</button>
      <!-- <button class="button" (click)="onOffWebCame()">Switch camera</button> -->
    </div>
    <webcam class="webcam" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
      *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
  </div>
</div>

<ng-template #noCameraExist>
  <div class="lottie-text-wrapper">
    <div class="lottie-container">
      <ng-lottie [options]="options"></ng-lottie>
    </div>
    <h4>Oops! Camera Devices are not available.</h4>
  </div>
</ng-template>
