import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Cashier } from 'src/app/core/stores/cashier.store';
import { Environment } from 'src/app/core/stores/environment';

@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.scss']
})
export class ShiftReportComponent implements OnInit {
  
  constructor(
    private router: Router,
    public store: Cashier,
    public env:Environment,
  ) { }

  ngOnInit() {}
 
  _goBack() {
    this.router.navigate(['/finance']);
  }


}