import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPolicyRemarksIcon'
})
export class GetPolicyRemarksIconPipe implements PipeTransform {

  transform(policyRemark: any): unknown {
    let result = null;
    if(!policyRemark) return;
    switch(policyRemark.name) {
      case 'OK': result = { name: 'Present', icon: 'check_circle', color: '#155724' }; break;
      case 'L': result = { name: 'Late', icon: 'watch_later', color: '#856404' }; break;
      case 'A': result = { name: 'Absent', icon: 'cancel', color: '#721c24' }; break;
      case 'M': result = { name: 'Mission', icon: 'business_center', color: '#0c5460' }; break;
      case 'P': result = { name: 'Permission', icon: 'turned_in', color: '#004085' }; break;
    }
    return result;
  }

}
