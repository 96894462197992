import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumDiscountArray'
})
export class SumDiscountArrayPipe implements PipeTransform {

  transform(data: Array<any>, args?: any): any {
    let value = 0
    data.map((m) => {
      if (m.globalDiscount) {
        const { amount } = m.globalDiscount
        value += amount
      }
    })
    return value;
  }

}
