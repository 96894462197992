import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DataService } from 'src/app/core/services/data.service';
import { MappingService, pushToArray } from '../services/mapping.service';

@Pipe({
  name: 'batchGroup'
})
export class BatchGroupPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return value.filter(m => m.batchKey === args)
    }
    return null;
  }

}

@Pipe({
  name: 'batchGroupLevel'
})
export class BatchGroupLevelPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return value.filter(m => m.level.key === args)
    }
    return null;
  }

}


@Pipe({
  name: 'filterHour'
})
export class FilterHourPipe implements PipeTransform {
  transform(item: any, day): any {
    if (item && day) {
      let data: any = [];
      for (const d of day) {
        const gethour = item.filter(m => m.days[d] === true).map(a => ({ ...a, day: d }));
        data.push(...gethour)
      }
      return data ? MappingService.orderBy(data, 'fromHoursNumber') : null
    }
    return null;
  }

}

@Pipe({
  name: 'dayText'
})
export class DayTextPipe implements PipeTransform {
  transform(item: any, day): any {
    if (item) {
      const toArraysDays = Object.keys(item).map(function (key) {
        return { name: key, value: item[key] };
      });
      const data: any = toArraysDays.filter(m => m.value === true);
      // for (const d of day) {
      // data.push(...gethour)
      //   const gethour = item[d] === true ? Object.keys(item[d]) : null
      //   data = gethour
      // }
      return data.name
      // return data ? MappingService.orderBy(data, 'fromHoursNumber') : null
    }
    return null;
  }

}


@Pipe({
  name: 'totalSAttendence'
})
export class TotalSAttendencePipe implements PipeTransform {
  constructor(private ds: DataService) { }
  async transform(sc, item, fdate, tdate): Promise<any> {
    const fromDateKey = moment(fdate).startOf('day').toDate();
    const toDateKey = moment(tdate).endOf('day').toDate();

    if (!item) return null;
    const data = await this.ds.academicYearRefCollection().doc(sc.academicYear.key).collection('schedules').doc(sc.key).collection('attendance', ref => ref.where('sessionKey', '==', item.key).where('created_at', '>=', fromDateKey).where('created_at', '<=', toDateKey)).get().toPromise()
    const alldata = pushToArray(data)

    if (alldata.length === 0) return 0;
    const sdata = await this.ds.academicYearRefCollection().doc(sc.academicYear.key).collection('schedules').doc(sc.key).collection('attendance').doc(alldata[0].key).collection('students').get().toPromise()
    const allsdata = pushToArray(sdata)
    return allsdata.length
    // return MappingService.orderBy(item.filter(m=>m.term && m.term.key===args),"courseCode");
  }

}
