import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterReportByCampus'
})
export class FilterReportByCampusPipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    return values.filter(m=>m.create_by.campus.key===args);
  }

}
