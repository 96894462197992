import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { SCHOOL_CONFIG } from 'src/environments/schoolConfig';
import { replaceStorageId } from './convert.service';

@Injectable({
  providedIn: 'root'
})
export class FireStorageService {
  uploadPercent: any = null;
  fileUrl: any = null;
  constructor(private storage: AngularFireStorage) { }

  uploadFile(event, filePath) {
    const file = event.target.files[0];
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    return task;
  }

  uploadSelectedFile(file, filePath) {
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    return task
  }

  async uploadFileGetDownloadURL(file, filePath) {
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    const fileUrl = await ref.getDownloadURL().toPromise();
    return {
      uploadTask: task,
      fileUrl: fileUrl,
    };
  }

  storageRef(filePath) {
    return this.storage.ref(filePath);
  }

}

export async function urlToBase64RadiusImg(url) {
  try {
    return await new Promise((resolve, reject) => {
      const radius: number = 40
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.setAttribute("crossOrigin", "anonymous");
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.beginPath();

        // Move to the top-left corner
        ctx.moveTo(radius, 0);
        // Create a rounded corner at the top-right
        ctx.arcTo(canvas.width, 0, canvas.width, canvas.height, radius);
        // Create a rounded corner at the bottom-right
        ctx.arcTo(canvas.width, canvas.height, 0, canvas.height, radius);
        // Create a rounded corner at the bottom-left
        ctx.arcTo(0, canvas.height, 0, 0, radius);
        // Create a rounded corner at the top-left
        ctx.arcTo(0, 0, radius, 0, radius);
        // Close the path
        ctx.closePath();
        // ctx.fillStyle = "transparent";
        ctx.fillStyle = "transparent";
        // ctx.roundRect(0, 0, 80, 100, 8);
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // Begin a new path to define the rounded corners

        // Clip to the defined path
        ctx.clip();
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = error => reject(error);
    });
  } catch (err) {
    // Handle the error
    console.error(err);
  }
}


export type ImageSizeType = '200' | '400' | '525' |'650'| '600' | '720' | '1024' | '1050' | '1280' | '1350'// | '1920'
export function transformImageSameTokenNo(uri: string, sizeX: ImageSizeType, sizeY?: ImageSizeType) {
  const [pathUrl, token, rawPath] = UrlToPath(uri)

  if (sizeX === '525') {
    sizeY = '650'
  }
  // console.log('sizeX 1', sizeX)
  // console.log('sizeY 2', sizeY)
  const [path, name] = fileNameAddSize(pathUrl, sizeX, sizeY ? sizeY : sizeX)
  // console.log('path', path)
  // console.log('name', name)
  const pathName = sizeX ? `${path ? `${path}/` : ''}thumbs/${name}` : `${path ? `${path}/` : ''}${name}`
  const ref = encodeURIComponent(pathName)
  return { uri: `${rawPath}${ref}?${token}` }
}

export function UrlToPath(url: string) {
  const rawSplit = url?.split('/').pop()?.split('?')
  const rawPath = url.replace(url?.split('/').pop() ?? '', '')
  if (Array.isArray(rawSplit) && rawSplit.length > 0) {
    return [decodeURIComponent(rawSplit[0]), rawSplit[1], rawPath]
  }
  return ['', '', '']
}

export function fileNameAddSize(pathUrl: string, sizeX?: ImageSizeType, sizeY?: ImageSizeType) {
  const imgname = pathUrl.split('/').pop()?.split('.').slice(0, -1).join('.') || pathUrl.split('/').pop() || ''
  const path = pathUrl.split('/').slice(0, -1).join('/')
  const urlWithoutExt = pathUrl.replace(/\.[^/.]+$/, '') || ''
  const ext = pathUrl.replace(urlWithoutExt, '') || '';
  const imgSize = sizeX ? `_${sizeX}x${sizeY ? sizeY : sizeX}` : ''
  const name = `${imgname}${imgSize}${ext}`
  return [path, name]
}
