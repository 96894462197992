<div class="dialog-wrapper" *mobxAutorun>
  <div class="dialog-header">
    <div class="title">
      {{data.title}}
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content">
    <div class="form-wrapper">
      <div class="ui form">
        <div class="personal-statistic">

          <div class="pst-arrival">
            <label class="title">
              <strong>Name:</strong>
              {{data?.item?.student?.full_name}}
            </label>

            <label class="title">
              <strong>ID:</strong>
              {{data?.item?.student?.puc_id}}
            </label>
            <label class="title">
              <strong>Class:</strong>
              {{data?.item?.schedule?.batch?.name }} (Subject: {{data?.item?.schedule?.schedule_subject?.subject.name }}
              )

            </label>
            <label class="title">
              <strong>Data:</strong>
              {{data?.item?.attendanceMovement.create_date.toDate()|date:'fullDate' }}
            </label>
            <label class="title">
              <strong>session:</strong>
              {{data?.item?.attendanceMovement?.session.fromHours}}
              -
              {{data?.item?.attendanceMovement?.session.toHours}}
            </label>

            <label class="title">
              <strong>Reason:</strong>
              {{data?.item?.requestChangeAttendance?.reason}}
            </label>

          </div>


        </div>
        <form [formGroup]="form">

          <div class="field">
            <label>Reason
              <span class="rq-sign">*</span>
            </label>
            <textarea formControlName="reject_reason" cols="30" rows="5"></textarea>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="flex1"></div>
    <a mat-dialog-close class="enbutton cancel m-r16" (click)="dialogRef.close('no')">Cancel</a>
    <button class="enbutton primary m-r16" [disabled]="!form.valid" (click)="create(form.value)">Reject</button>
  </div>
  <!-- <app-spinner *ngIf="store?.process"></app-spinner> -->
</div>
