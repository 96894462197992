import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { Environment } from './environment';
import { AdministratorService } from '../services/administrator.service';
import { DataService } from '../services/data.service';
import { toNumber, ConvertService } from 'src/app/shared/services/convert.service';
import { MappingService, studentObj, userObj } from 'src/app/shared/services/mapping.service';
import { DISCOUNT_TYPES, enrollmentTypes, invoiceTypesObj, paymentStatus, paymentType, recordStatus } from '../dummy/stauts';
import { IReceivedPayment } from '../interfaces/receivedPayment';
import { IInvoice } from '../interfaces/invoice';
import { IStudentAccount } from '../interfaces/student';
import * as firebase from 'firebase/app';
import { IPrepaid } from '../interfaces/prepaid';

@Injectable({ providedIn: 'root' })
export class ReceivePaymentStore {
  @observable public invoiceKey = null;
  @observable public admissionKey = null;
  @observable public invoice = null;
  @observable public allInvoices = null;
  @observable public invoiceHeader = null;
  @observable public receiptHeader = null;
  @observable public totalTuitionFee = null;
  @observable public totalTestingFee = null;
  @observable public totalSubject = null;
  @observable public otherFee = null;

  // @observable public student = null;
  @observable public filterType = null;
  @observable public data = null;

  @observable public loading = false;
  @observable public process = false;
  @observable public empty = false;

  @observable public header = null;
  @observable public detail = null;
  @observable public totalPrice = null;
  @observable public totalMiscellaneous = null;
  @observable public totalScholarshipPenalty = null;
  @observable public gradePrice = null;
  @observable public subtotal = null;
  @observable public enroll = null;
  @observable public enrollIncluded = null;
  @observable public enrollExcluded = null;

  @observable public studentScholarship = null;
  @observable public totalScholarship = null;
  @observable public totalLoan = null;
  @observable public totalPrepaid = null;
  @observable public prepaidRef = null;
  @observable public totalInstallment = null;
  @observable public remainingInstallment = null;
  @observable public totalPenalty = null;
  @observable public penaltyRef = null;
  @observable public grandTotal = null;
  @observable public totalLate = null
  @observable public draft = null;
  @observable public scholarshipPenalty = null;
  @observable public miscellaneousFee = null;
  @observable public activeTerm = null;
  @observable public scholarshipRef = null;
  @observable public installmentRef = null;
  @observable public installment = null;
  @observable public totalDraft = [];
  @observable public totalScholarShipPenalty = [];
  @observable public paymentEmpty = false;
  @observable public paymentDetail = null;
  @observable public paymentTesting = null;
  @observable public paymentTuitionFee = null;
  @observable public paymentScholarshipPenalty = null;
  @observable public paymentMiscellaneous = null;

  @observable public totalPaymentDiscount = null;
  @observable public paymentDiscountType = null;
  @observable public scholarship = null;
  @observable public totalDiscount = null;
  @observable public paymentOtherFee = null;
  @observable public tuitionFees = [];

  @observable.shallow public testing = null;
  @observable.shallow public paymentHeader = null;
  @observable public totalOtherFee = null;
  @observable public otherFees = null;

  constructor(
    private ds: DataService,
    private as: AdministratorService,
    public env: Environment,
  ) { }

  @action
  fetchStudentPaidInvoices(studentKey: string) {
    this.loading = true;
    this.ds.invoiceAllByStudentKeyRef(studentKey).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.allInvoices = MappingService.orderByDesc(docs, "received_date");
      this.loading = false;
    })
  }

  @action
  fetchInvoiceItems(studentKey: string, headerKey: string, callback) {
    this.enrollExcluded = null;
    this.enrollIncluded = null;
    this.enroll = null;
    this.empty = true;
    this.tuitionFees = [];
    this.totalTuitionFee = null;
    this.totalTestingFee = null;
    this.otherFee = null;
    this.totalScholarShipPenalty = null;
    this.totalScholarship = null;
    this.totalMiscellaneous = null;
    this.totalPrepaid = null;
    this.totalInstallment = null;
    this.ds.invoiceItemsRef(studentKey, headerKey).valueChanges().subscribe(docs => {
      this.receiptHeader = docs.filter(m => m.isHeader === true && m.isPaid.key === paymentStatus.paid.key)[0];
      //=====TESTING
      const { invoice_type } = this.receiptHeader;
      if (invoice_type && (invoice_type.key === invoiceTypesObj.registrationFee.key)) {
        this.totalTestingFee = this.receiptHeader.amount;
      } else {
        this.tuitionFees = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
        const totalFee = MappingService.sumTuitionFees(this.tuitionFees);
        if (totalFee > 0) this.totalTuitionFee = totalFee;

        this.otherFee = docs.filter(m => m.invoice_type.key !== 2 && m.isHeader == false);
        this.totalSubject = this.tuitionFees.length;
      }
      callback(docs);
    })
  }

  clearPayment() {
    // this.totalScholarship = null;
    // this.totalLoan = null;
    // this.totalPaymentDiscount = null;
    // this.paymentDiscountType = null;
    // this.totalDiscount = null;
    // this.penaltyRef = null;
    // this.totalInstallment = null;
    // this.totalPrice = null;
    // this.gradePrice = 0;
    // this.grandTotal = null;
    // this.totalLate = null;
    // this.invoice = null;
    // this.invoiceHeader = null;
    // this.scholarshipRef = null;
    // this.totalScholarShipPenalty = null;
    // this.paymentScholarshipPenalty = null;
    // this.totalScholarshipPenalty = null;
    // this.paymentEmpty = false;
    // this.totalMiscellaneous = null;
    // this.totalScholarshipPenalty = null;

    this.receiptHeader = null;

    this.scholarship = null;
    this.paymentTuitionFee = [];
    this.paymentMiscellaneous = [];
    this.paymentOtherFee = [];

    this.totalPrepaid = null;
    this.totalPenalty = null;
    this.penaltyRef = null;

    this.paymentHeader = null;
    this.paymentDetail = null;
  }

  @action
  fetchStudentInvoices(studentKey: string, admissionKey: string, callback: any) {
    this.paymentHeader = null;
    this.paymentDetail = null;
    this.paymentEmpty = true;
    let invoices = [];
    this.ds.studentInvoiceByAdmission(admissionKey, studentKey, null).valueChanges().subscribe(allInv => {
      if (allInv && allInv.length > 0) {
        this.paymentHeader = allInv.filter(m => !m.isVoid && m.isHeader)[0];
        invoices = allInv.filter(m => !m.isVoid && m.headerRef === this.paymentHeader.key);
        this.paymentDetail = invoices.filter(m => !m.isHeader)
      }

      this.paymentEmpty = invoices.length === 0;
      callback(invoices);
    })
  }

  @action
  async fetchReceivePayment(studentKey: string, admissionKey: string, callback: any) {
    this.loading = true;
    this.clearPayment();
    const admissionDoc = await this.ds.admissionRef().doc(admissionKey).get().toPromise();
    const studentDoc = await this.ds.studentDocument(studentKey).get().toPromise();

    const admission = MappingService.pushToObject(admissionDoc);
    const student = MappingService.pushToObject(studentDoc);

    this.fetchStudentInvoices(studentKey, admissionKey, async (docs) => {

      this.scholarship = await this.fetchStudentScholarship(admission);

      this.paymentTuitionFee = docs.filter(m => m.isHeader === false && m.invoice_type.key === invoiceTypesObj.tuitionFee.key);
      this.paymentMiscellaneous = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.miscellaneous.key));
      this.paymentOtherFee = docs.filter(m => m.isHeader === false && (m.invoice_type.key === invoiceTypesObj.otherFee.key));

      const tuitionFee = this.paymentTuitionFee && this.paymentTuitionFee.length > 0 ? this.paymentTuitionFee[0] : null;
      const globalDiscount = await this.getGlobalDiscount(tuitionFee);
      const scholarshipData = await this.getStudentScholarship(tuitionFee, this.scholarship);

      let totalDeduction = 0;
      const { prepaid } = student;
      if (prepaid && prepaid > 0) {
        this.totalPrepaid = ConvertService.toNumber(prepaid);
      }

      totalDeduction = ConvertService.toNumber(this.totalPrepaid);
      //=====HAS DISCOUNT FEE
      if (this.paymentHeader && this.paymentHeader.discount && this.paymentHeader.discount > 0) {
        let { discount } = this.paymentHeader;
        this.totalDiscount = discount;
      }

      //=====HAS PENALTY FEE
      if (this.paymentHeader && this.paymentHeader.penalty && this.paymentHeader.penalty > 0) {
        let { penalty, penaltyRef } = this.paymentHeader;
        this.totalPenalty = penalty;
        this.penaltyRef = penaltyRef;
      }

      //=====HAS MISCELLANEOUS FEE
      if (this.paymentMiscellaneous) {
        this.totalMiscellaneous = MappingService.sum(this.paymentMiscellaneous, 'amount');
      }

      //=====HAS OTHER FEE
      if (this.paymentOtherFee) {
        this.totalOtherFee = MappingService.sum(this.paymentOtherFee, 'amount');
      }

      //=====HAS TUITIONS FEE
      if (this.paymentTuitionFee) {
        this.totalPrice = MappingService.sum(this.paymentTuitionFee, 'amount');
      }

      // let deductionAmount = 0;
      // if (totalDeduction > this.totalPaymentDiscount) {
      //   deductionAmount = totalDeduction;
      //   this.totalPaymentDiscount = 0;
      //   this.paymentDiscountType = null
      // }

      // if (totalDeduction < this.totalPaymentDiscount) {
      //   deductionAmount = this.totalPaymentDiscount;
      //   this.scholarshipRef = null;
      //   this.totalScholarship = null;
      //   this.totalLoan = null;
      // }

      this.gradePrice = ConvertService.toNumber(this.totalPrice) - ConvertService.toNumber(totalDeduction);
      this.grandTotal = this.gradePrice + ConvertService.toNumber(this.totalOtherFee) + ConvertService.toNumber(this.totalMiscellaneous) + ConvertService.toNumber(this.totalPenalty);
      this.loading = false;
      callback(this.paymentHeader);
    })
  }

  @action
  async fetchStudentScholarship(admission: any) {
    let scholarship = null;
    const { program_academic, student } = admission;
    if (program_academic && program_academic.scholarshipKey) {
      const scholarshipDoc = await this.ds.studentDocument(student.key).collection("scholarships").doc(program_academic.scholarshipKey).get().toPromise();
      scholarship = MappingService.pushToObject(scholarshipDoc);
    }
    return scholarship;
  }

  @action
  async getGlobalDiscount(tuitionFee: any) {
    const paymentDiscountDoc = await this.ds.paymentDiscountRef().get().toPromise();
    const paymentDiscountData = MappingService.pushToArray(paymentDiscountDoc);
    let discountType = null;
    let discountAmount = null;
    if (tuitionFee) {
      const { fee, amount } = tuitionFee;
      const discountBalanceData = paymentDiscountData.filter(m => m.payment_option.key === fee.paymentOption.key);
      if (discountBalanceData && discountBalanceData.length > 0) {
        const { discount_type, discount } = discountBalanceData[0];
        discountType = discount_type;
        discountAmount = discount_type.key === 1 ? (amount * toNumber(discount)) / 100 : discount;
      }
    }
    return {
      discount_type: discountType,
      discount_amount: discountAmount,
    };
  }

  @action
  async getStudentScholarship(tuitionFee: any, scholarship: any) {
    let scholarshipType = null;
    let scholarshipAmount = null;
    if (scholarship) {
      const { percentage, cash } = scholarship;
      switch (scholarship) {
        case percentage > 0:
          scholarshipAmount = (tuitionFee.amount * toNumber(percentage)) / 100;
          scholarshipType = DISCOUNT_TYPES[0]
          break;
        case cash > 0:
          scholarshipAmount = cash;
          scholarshipType = DISCOUNT_TYPES[1]
          break;
        default:
          scholarshipAmount = null;
          scholarshipType = null;
          break;
      }
    }
    return {
      scholarship_type: scholarshipType,
      scholarship_amount: scholarshipAmount,
    };
  }


  action
  async schoolPayment(item: IReceivedPayment, student: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const userData = await this.ds.userRef(item.cashier.key).get().toPromise();
    const userDoc = MappingService.pushToObject(userData);

    const sysData = await this.ds.sysSetting().get().toPromise();
    const sysDoc = MappingService.pushToObject(sysData);

    const userRef = this.ds.userFireRef();
    const shiftMovementRef = this.ds.shiftMovementFireRef();

    const studentRef = this.ds.studentFireRef();
    const studentAccountRef = this.ds.studentAccountDocFire();
    const receivedPaymentRef = this.ds.receivedPaymentFireRef().doc(item.key);
    const settingRef = this.ds.settingFireStore();

    const headerKey = this.paymentHeader.key;
    const invoiceNo = ConvertService.generate_testing_invoiceNo(sysDoc);

    const { create_date, payment_year, shift, cashIn, note } = item;
    const { puc_id } = student;

    const create_by = userObj(item.create_by);
    const date = create_date;

    //===== New ID
    if (!puc_id) {
      const pucID = ConvertService.generate_puc_id(sysDoc);
      const emailStudent = pucID + "@gmail.com";
      student.puc_id = pucID;
      student.email = emailStudent;
      const studentAccount: IStudentAccount = {
        key: student.key,
        create_date: new Date(),
        create_by: item.create_by,
        status: recordStatus.active,
        puc_id: pucID,
        email: emailStudent,
        fileUrl: null,
        displayName: student.full_name,
        studentKey: student.key,
        phone: student.mobile_phone,
        token: null,
        pinCode: null,
        student: studentObj(student),
        resetAuth: true,
      };

      batch.update(studentRef.doc(student.key), {
        puc_id: pucID,
        email: emailStudent,
      })
      batch.set(studentAccountRef.doc(studentAccount.key), studentAccount);

      // NEW ID NUMBER
      batch.update(settingRef, { puc_id: firebase.firestore.FieldValue.increment(1) });
    }

    // NEW INVOICE NUMBER
    batch.update(settingRef, { invoice_shufit: firebase.firestore.FieldValue.increment(1) });

    // const { invoiceKey, totalPrepaid, totalInstallment,
    //   paymentTuitionFee, grandTotal, totalScholarship,
    //   totalLoan, penaltyRef, totalPenalty, totalPaymentDiscount } = payParam;
    //===== CLEAR STUDENT INVOICE
    if (this.invoiceKey === headerKey) {
      batch.update(studentRef.doc(student.key), {
        invoiceKey: null,
      })
    }


    this.paymentDetail.forEach(invoice => {
      let courseAmount = invoice.amount;
      let enrollScholarship = null;
      let enrollLoan = null;
      if (invoice.invoice_type.key === invoiceTypesObj.tuitionFee.key) {
        // const enrollLength = paymentTuitionFee.length;
        // enrollScholarship = ConvertService.toNumber(totalScholarship / enrollLength);
        // enrollLoan = ConvertService.toNumber(totalLoan / enrollLength);
        // courseAmount = courseAmount - (enrollScholarship + enrollLoan) - toNumber(totalPaymentDiscount);
      } else {
        courseAmount = invoice.amount
      };
      courseAmount = ConvertService.toFloatFixed2(courseAmount);
      const receiveDetailData = {
        amount: courseAmount,
        penaltyRef: ConvertService.toNull(this.penaltyRef),
        penalty: ConvertService.toNumber(this.totalPenalty),
        scholarshipRef: ConvertService.toNull(this.scholarship),
        scholarship: ConvertService.toNull(enrollScholarship),
        loan: ConvertService.toNull(enrollLoan),
        prepaidRef: ConvertService.toNull(this.prepaidRef),
        prepaid: ConvertService.toNull(this.totalPrepaid),
        received_by: create_by,
        received_date: date,
        received_date_key: ConvertService.toDateKey(date),
        received_campus: ConvertService.toNull(create_by.campus),
        received_campusKey: create_by.campus.key,
        received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,
        dailyShift: shift,
        caseIn: cashIn,
        note: note,
        payment_type: paymentType.cash,
        isPaid: paymentStatus.paid,
        invoice_no: invoiceNo,
        payment_year: payment_year,
        student: studentObj(student),

        // discount: toNumber(totalPaymentDiscount),
      }

      batch.update(studentRef.doc(student.key).collection("invoices").doc(invoice.key), {
        ...receiveDetailData
      })

    })

    const receiveHeaderData = {
      amount: ConvertService.toFloatFixed2(this.grandTotal),
      penaltyRef: ConvertService.toNull(this.penaltyRef),
      penalty: ConvertService.toNumber(this.totalPenalty),
      scholarshipRef: ConvertService.toNull(this.scholarship),
      //
      scholarship: null,
      loan: null,
      prepaidRef: ConvertService.toNull(this.prepaidRef),
      prepaid: ConvertService.toNull(this.totalPrepaid),
      received_by: create_by,
      received_date: date,
      received_date_key: ConvertService.toDateKey(date),
      received_campus: ConvertService.toNull(create_by.campus),
      received_campusKey: create_by.campus.key,
      received_campusRef: this.ds.campusRef().doc(create_by.campus.key).ref,

      dailyShift: shift,
      caseIn: cashIn,
      note: note,
      payment_type: paymentType.cash,
      isPaid: paymentStatus.paid,
      invoice_no: invoiceNo,
      payment_year: payment_year,
      student: studentObj(student),

      // discount: toNumber(totalPaymentDiscount),
    }

    item.invoiceKey = headerKey;
    item.studentKey = student.key;
    batch.set(receivedPaymentRef, item);

    batch.update(studentRef.doc(student.key).collection("invoices").doc(headerKey), {
      ...receiveHeaderData
    })

    // UPDATE USER BALANCE
    const balanceAmount = ConvertService.toNumber(userDoc.balance) + ConvertService.toNumber(receiveHeaderData.amount);
    batch.update(userRef.doc(userDoc.key), {
      balance: balanceAmount
    })
    batch.update(shiftMovementRef.doc(userDoc.shift.key), {
      balance: balanceAmount
    })

    batch.commit().then(() => {
      this.process = false;
      callback(true, this.paymentHeader);
    })
      .catch(error => {
        callback(false, error);
        this.process = false;
      });
  }

}

