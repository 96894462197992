import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from 'src/app/core/services/inventory.service';

@Pipe({
  name: 'productInfo'
})
export class ProductInfoPipe implements PipeTransform {
  
  constructor(private ds: InventoryService) { }

  async transform(productKey?: any, ...args: unknown[]) {
    if (productKey) {
      const doc=await this.ds.productRef().doc(productKey).get().toPromise()
      return doc.data();
    }
    return null;
  }

}