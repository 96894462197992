import { MappingService } from '../services/mapping.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userCampus'
})
export class UserCampusPipe implements PipeTransform {

  transform(value: Array<any>, args?: any): any {
    let newArray = []
    if(!args) return
    const { memberOf } = args;
    if (memberOf.key == 2) {
      let data = value.filter(f => f.key === args.campusKey)
      newArray.push(...data);
      return newArray
    } else {
      return value
    }
  }

}
