import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-list-preview',
  templateUrl: './file-list-preview.component.html',
  styleUrls: ['./file-list-preview.component.scss']
})
export class FileListPreviewComponent implements OnInit {
@Input() files:any[]=[];
@Output() onRemove=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
