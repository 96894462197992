import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findAttConfirmLogs'
})
export class FindAttConfirmLogsPipe implements PipeTransform {

  transform(session: any, attendanceConfirmationLogs: Array<any>): unknown {
    if(!attendanceConfirmationLogs || attendanceConfirmationLogs?.length === 0) return 'pending';
    const result = attendanceConfirmationLogs?.find((log) => log.sessionKey === session.key);
    return result ? 'submitted' : 'pending';
  }

}
